import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { USER_TYPES } from '../constants/ApplicationConstants';
import {
  getFormattedMsg,
  getCurrentCropYear,
  isGrowerInactive,
} from '../utilities/UtilityFunctions';
import menus from '../constants/ApplicationMenus';
import {
  checkProfileComplete,
  checkSeedAdvisorSelected,
  getCreditDetOfGrower,
} from '../grower/profile/common';
import { isSeedAdvProfileComplete } from '../seed-advisor/dashboard/common';

const selectLoginState = (state) => state.get('user', initialState);

const formatGrowerUser = (currUser) => {
  const newUser = { ...currUser };
  newUser.growerProfileCompleted = checkProfileComplete(newUser);
  newUser.growerSelectedSeedAdvisor = !!newUser.grower_seed_advisors.find(
    (item) => item?.year === getCurrentCropYear()
  );
  newUser.seedAdviserSelected = checkSeedAdvisorSelected(newUser);
  newUser.isProfileComplete =
    newUser.growerProfileCompleted && newUser.seedAdviserSelected;
  newUser.disableApply = isGrowerInactive(newUser);
  return {
    ...newUser,
    ...getCreditDetOfGrower(
      newUser.grower_credit_histories || [],
      getCurrentCropYear()
    ),
  };
};

const formatSeedAdvisor = (currUser) => {
  const newUser = { ...currUser };
  (currUser.grower_seed_advisors || []).forEach((grower) => {
    newUser.growers = [...(newUser.growers || [])];
    const index = newUser.growers.findIndex((s) => grower.grower === s.id);
    if (index > -1) {
      newUser.growers[index] = {
        ...newUser.growers[index],
        invitation_sent: grower.invitation_sent,
      };
    }
  });
  newUser.isProfileComplete = isSeedAdvProfileComplete(newUser);
  return newUser;
};

const formatSalesRepresentative = (currUser) => {
  const newUser = { ...currUser };
  newUser.isProfileComplete = true;
  return newUser;
};

const formatAdmin = (currUser) => {
  const newUser = { ...currUser };
  newUser.isProfileComplete = true;
  return newUser;
};

const makeSelectUser = createSelector(selectLoginState, (loginState) =>
  getFormattedUser(loginState.get('user'))
);

const makeSelectUserType = createSelector(
  selectLoginState,
  (loginState) => (loginState.get('user') || {}).userType
);

const isToggleOn = (state, toggleName) => {
  const user = makeSelectUser(state) || {};

  return Boolean(user.toggles && user.toggles[toggleName]);
};

const makeSelectUserToggles = createSelector(
  selectLoginState,
  (loginState) => (loginState.get('user') || {}).toggles
);

const makeSelectViewUser = createSelector(selectLoginState, (loginState) =>
  getFormattedUser(loginState.get('viewUser'))
);

const getFormattedUser = (currUser) => {
  if (currUser) {
    if (USER_TYPES.GROWER === currUser.userType) {
      return formatGrowerUser(currUser);
    } else if (USER_TYPES.SEED_ADVISOR === currUser.userType) {
      return formatSeedAdvisor(currUser);
    } else if (USER_TYPES.SALES_REPRESENTATIVE === currUser.userType) {
      return formatSalesRepresentative(currUser);
    } else if (USER_TYPES.ADMIN === currUser.userType) {
      return formatAdmin(currUser);
    }
  }
  return currUser;
};

const getUserMenus = createSelector(
  makeSelectUserType,
  makeSelectUserToggles,
  makeSelectUser,
  (userType, toggles, user) => {
    if (!userType) {
      return [];
    }
    let finalMenus;
    switch (userType) {
      case USER_TYPES.GROWER:
        if (isGrowerInactive(user)) {
          const applyMenuItemIndex = menus.grower.findIndex(
            (menuItem) => !!menuItem.isApplyForCredit
          );
          menus.grower[applyMenuItemIndex].disabled = true;
        }
        finalMenus = menus.grower;
        break;
      case USER_TYPES.SEED_ADVISOR:
        finalMenus = menus.seed_advisor;
        break;
      case USER_TYPES.SALES_REPRESENTATIVE:
        finalMenus = menus.sales_representative;
        break;
      case USER_TYPES.ADMIN:
        finalMenus = menus.admin;
        break;
      case USER_TYPES.CUST_SUPPORT_ADMIN:
        finalMenus = menus.customerSupport;
        break;
      default:
        finalMenus = [];
    }
    const showMenuItem = (allToggles = {}, toggleName) => {
      return Boolean(!toggleName || allToggles[toggleName]);
    };

    return finalMenus
      .filter((menu) => showMenuItem(toggles, menu.toggle))
      .filter((menu) => !menu.hide)
      .map((menu) => ({
        ...menu,
        label: getFormattedMsg(menu.label),
      }));
  }
);
export {
  selectLoginState,
  makeSelectUser,
  getUserMenus,
  makeSelectViewUser,
  isToggleOn,
};
