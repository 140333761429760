import React from 'react';
import { MessageHOC } from '../../shared';
import { Row, Col, Modal, Table } from 'antd';
import './cookieconsent.less';
export const CookieInfo = (props) => {
  const messages = props.messages.CookieInfo;
  const columns = [
    {
      title: messages.name,
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: messages.provider,
      dataIndex: 'provider',
      key: 'provider',
      width: 150,
    },
    {
      title: messages.purpose,
      dataIndex: 'purpose',
      key: 'purpose',
      width: 150,
    },
    {
      title: messages.expiry,
      dataIndex: 'expiry',
      key: 'expiry',
      width: 150,
    },
    {
      title: messages.type,
      dataIndex: 'type',
      key: 'type',
      width: 150,
    },
  ];

  const statDataSource = [
    {
      key: '1',
      name: '_ga',
      provider: 'goldenadvantage.com',
      purpose: messages._ga_purpose,
      expiry: '2 years',
      type: 'HTTP Cookie',
    },
    {
      key: '2',
      name: '_gat',
      provider: 'goldenadvantage.com',
      purpose: messages._gat_purpose,
      expiry: '1 day',
      type: 'HTTP Cookie',
    },
    {
      key: '3',
      name: '_gid',
      provider: 'goldenadvantage.com',
      purpose: messages._gid_purpose,
      expiry: '1 day',
      type: 'HTTP Cookie',
    },
    {
      key: '4',
      name: 't',
      provider: 'signin.syngenta.com',
      purpose: messages.t_purpose,
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '5',
      name: 'dtCookie',
      provider: 'syngentadigitalapps.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
  ];

  const nessDataSource = [
    {
      key: '1',
      name: 'CookieConsent',
      provider: 'goldenadvantage.com',
      purpose: messages.cookieConsent_purpose,
      expiry: '30 days',
      type: 'HTTP Cookie',
    },
    {
      key: '2',
      name: 'sid',
      provider: 'signin.syngenta.com',
      purpose: messages.sid_purpose,
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '3',
      name: 'JSESSIONID',
      provider: 'signin.syngenta.com',
      purpose: messages.jsessionid_purpose,
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '4',
      name: 'CONSENT',
      provider: 'youtube.com',
      purpose: messages.yconsent_purpose,
      expiry: '2 Years',
      type: 'HTTP Cookie',
    },
  ];

  const marDataSource = [
    {
      key: '1',
      name: 'DT',
      provider: 'signin.syngenta.com',
      purpose: messages.dt_purpose,
      expiry: '2 Years',
      type: 'HTTP Cookie',
    },
    {
      key: '2',
      name: 'YSC',
      provider: 'youtube.com',
      purpose: messages.ysc_purpose,
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '3',
      name: 'VISITOR_INFO1_LIVE',
      provider: 'youtube.com',
      purpose: messages.visitor_info1_live_purpose,
      expiry: '179 days',
      type: 'HTTP Cookie',
    },
    {
      key: '4',
      name: 'yt-remote-device-id',
      provider: 'youtube.com',
      purpose: messages.yt_remote_device_id_purpose,
      expiry: 'Persistent',
      type: 'HTML Local Storage',
    },
    {
      key: '5',
      name: 'yt-remote-connected-devices',
      provider: 'youtube.com',
      purpose: messages.yt_remote_connected_devices_purpose,
      expiry: 'Persistent',
      type: 'HTML Local Storage',
    },
    {
      key: '6',
      name: 'yt-remote-session-app',
      provider: 'youtube.com',
      purpose: messages.yt_remote_session_app_purpose,
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '7',
      name: 'yt-remote-cast-installed',
      provider: 'youtube.com',
      purpose: messages.yt_remote_cast_installed_purpose,
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '8',
      name: 'yt-remote-session-name',
      provider: 'youtube.com',
      purpose: messages.yt_remote_session_name_purpose,
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '9',
      name: 'yt-remote-cast-available',
      provider: 'youtube.com',
      purpose: messages.yt_remote_cast_available_purpose,
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '10',
      name: 'yt-remote-fast-check-period',
      provider: 'youtube.com',
      purpose: messages.yt_remote_fast_check_period_purpose,
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
  ];
  const unclaDataSource = [
    {
      key: '1',
      name: 'okta-oauth-redirect-params',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '2',
      name: 'okta-oauth-nonce',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '3',
      name: 'okta-oauth-state',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTTP Cookie',
    },
    {
      key: '4',
      name: 'okta-pkce-storage',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Persistent',
      type: 'HTML Local Storage',
    },
    {
      key: '5',
      name: 'okta-cache-storage',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Persistent',
      type: 'HTML Local Storage',
    },
    {
      key: '6',
      name: 'okta-token-storage',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Persistent',
      type: 'HTML Local Storage',
    },
    {
      key: '7',
      name: 'userInfo',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '8',
      name: 'userEmail',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
    {
      key: '9',
      name: 'okta-oauth-redirect-params',
      provider: 'goldenadvantage.com',
      purpose: '',
      expiry: 'Session',
      type: 'HTML Local Storage',
    },
  ];
  return (
    <Modal
      centered={true}
      visible={props.showModal}
      footer={null}
      width={'90%'}
      zIndex={1000000}
      onCancel={props.closeCookieDetail}
    >
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten1}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>{messages.senten2}</div>
        </Col>
      </Row>
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten3}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten4}</div>
          <div className="pb10">{messages.senten5}</div>
          <div className="pb10">{messages.senten6}</div>
          <div className="pb10">{messages.senten7}</div>
          <div className="pb10">
            <div>{messages.senten8}</div>
            <div>{messages.senten9}</div>
            <div>{messages.senten10}</div>
          </div>
        </Col>
      </Row>
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten11}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>{messages.senten12}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="cookie-details-section">
            <Row className="header">
              <Col>
                <h3 className="pt10  font-14 bold">
                  {messages.senten13} ({statDataSource.length})
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>{messages.senten14}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={statDataSource}
                  columns={columns}
                  pagination={false}
                  size="small"
                  scroll={{ x: 750 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="cookie-details-section">
            <Row className="header">
              <Col>
                <h3 className="pt10  font-14 bold">
                  {messages.senten15} ({nessDataSource.length})
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>{messages.senten16}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={nessDataSource}
                  columns={columns}
                  pagination={false}
                  size="small"
                  scroll={{ x: 750 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="cookie-details-section">
            <Row className="header">
              <Col>
                <h3 className="pt10  font-14 bold">
                  {messages.senten17} ({marDataSource.length})
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>{messages.senten18}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={marDataSource}
                  columns={columns}
                  pagination={false}
                  size="small"
                  scroll={{ x: 750 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="cookie-details-section">
            <Row className="header">
              <Col>
                <h3 className="pt10  font-14 bold">
                  {' '}
                  {messages.senten19} ({unclaDataSource.length})
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>{messages.senten20}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={unclaDataSource}
                  columns={columns}
                  pagination={false}
                  size="small"
                  scroll={{ x: 750 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten21}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten22}</div>
        </Col>
      </Row>
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten23}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>{messages.senten24}</div>
        </Col>
      </Row>
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten25}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten26}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten27}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="pb10">{messages.senten28}</div>
        </Col>
      </Row>
      <Row className="header">
        <Col span={24}>
          <h3 className="pt10  font-14 bold">{messages.senten29}</h3>
        </Col>
      </Row>
    </Modal>
  );
};
export default MessageHOC()(CookieInfo);
