function isCreditHistoryWrittenOff(creditHistory) {
  return creditHistory.write_off_amount && creditHistory.write_off_date;
}

function getAvailableCredit(creditHistory) {
  const {
    credit_line_used: creditLineUsed,
    credit_line_approved: creditLineApproved,
  } = creditHistory;

  if (isCreditHistoryWrittenOff(creditHistory) || !creditLineApproved) return 0;
  return creditLineApproved - (creditLineUsed || 0);
}

function getPaymentDue(creditHistory) {
  const {
    credit_line_used: creditLineUsed,
    payment_amount: paymentAmount,
    service_charges: serviceCharges,
  } = creditHistory;
  if (isCreditHistoryWrittenOff(creditHistory)) return 0;

  if (creditLineUsed && creditLineUsed >= 0) {
    return (
      creditLineUsed - (Math.abs(paymentAmount) || 0) + (serviceCharges || 0)
    );
  }
  return 0;
}

module.exports = { getAvailableCredit, getPaymentDue };
