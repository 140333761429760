export const SET_DROPDOWN_VALUE = 'app/common/SET_DROPDOWN_VALUE';

const setDropdownValue = (locale, name, value) => ({
  type: SET_DROPDOWN_VALUE,
  locale,
  name,
  value,
});

export { setDropdownValue };
