import React, { Component } from 'react';

import { Menu } from 'antd';
import { isEqual } from 'lodash';
import { NavLink } from 'react-router-dom';
import './menus.less';

const MenuItemIcon = (props) => {
  const { menuItem } = props;

  if (menuItem.renderIcon) {
    const Component = menuItem.renderIcon();

    return <Component className="custom-side-menu-icon" />;
  }
  return (
    <img
      src={menuItem.svg}
      alt="Menu Icon"
      className="anticon side-menu-icon"
    />
  );
};

export class Menus extends Component {
  shouldComponentUpdate(nextProps) {
    const { menuItems, currPath } = this.props;
    const nextMenuItems = nextProps.menuItems;
    const nextCurrPath = nextProps.currPath;
    return !(
      isEqual(menuItems, nextMenuItems) && isEqual(currPath, nextCurrPath)
    );
  }

  getMenuItems = () => {
    const { menuItems, toggleMenu } = this.props;
    return (menuItems || []).map((menu) => {
      return (
        <Menu.Item
          key={menu.navLink}
          disabled={menu.disabled}
          onClick={() => {
            if (toggleMenu) {
              toggleMenu();
            }
            if (menu.navLink === '/customer-support/seed-advisors') {
              localStorage.setItem('currentTab', 'overview');
            }
          }}
        >
          <NavLink to={menu.navLink}>
            <MenuItemIcon menuItem={menu} />
            <span>{menu.label}</span>
          </NavLink>
        </Menu.Item>
      );
    });
  };

  render() {
    const finalMenu = this.getMenuItems();
    if (finalMenu.length > 0) {
      const { currPath } = this.props;
      return (
        <Menu
          className="main-menu"
          theme="dark"
          mode="inline"
          selectedKeys={[currPath]}
        >
          {finalMenu}
        </Menu>
      );
    }
    return null;
  }
}
