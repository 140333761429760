import React from 'react';
import { Form, Input, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { isRequired } from '../../utilities/UtilityFunctions';

const Password = ({
  input,
  placeholder,
  label,
  disabled,
  meta,
  rules,
  showText,
  hideText,
}) => {
  let customClass = meta.error ? 'ant-form-item-has-error' : '';
  if (isRequired(rules)) {
    customClass = `${customClass} item-required`;
  }
  return (
    <Form.Item labelCol={{ span: 24 }} label={label} className={customClass}>
      <Input.Password
        onChange={input.onChange}
        value={input.value || ''}
        disabled={disabled}
        data-recording-sensitive
        placeholder={placeholder || ''}
        autoComplete="new-password"
        iconRender={(visible) =>
          visible ? (
            <Tooltip placement="bottom" title={hideText}>
              <EyeTwoTone />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title={showText}>
              <EyeInvisibleOutlined />
            </Tooltip>
          )
        }
      />
      {meta.error && <div className="ant-form-item-explain">{meta.error}</div>}
    </Form.Item>
  );
};

export { Password };
