import React from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { Menus } from './Menus';

const SideMenu = ({ isSiderCollapsed, currPath, menuItems }) => {
  const { Sider } = Layout;
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={isSiderCollapsed}
      className={classNames('main-sider', {
        'main-sider-expanded-width': !isSiderCollapsed,
        'main-sider-width-hidden': isSiderCollapsed,
      })}
    >
      <Menus menuItems={menuItems} currPath={currPath} />
    </Sider>
  );
};

export default SideMenu;
