import { fromJS } from 'immutable';

export const initialState = fromJS({
  selectedDistrict: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CAS_DISTRICT':
      return state.set('selectedDistrict', action.district);
    default:
      return state;
  }
};
