import { fromJS } from 'immutable';
import { SET_SESSION_ACTION } from '../constants/ApplicationConstants';

export const initialState = fromJS({
  session: undefined,
});

export default (state = initialState, action) => {
  if (SET_SESSION_ACTION === action.type) {
    return state.merge({
      session: action.payload.session,
    });
  }
  return state;
};
