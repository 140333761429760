import React, { useState, useEffect } from 'react';
import logo from '../../client/images/GH_GoldenAdvantage_Logo_RGB.png';
import './maintenance.less';
import { getEnvironmentValue, REDIRECT_DOMAIN } from '../../envConfig';

export const RedirectToNewDomain = (props) => {
  const [seconds, setSeconds] = useState(15);
  const location = props.location;
  const redirectDomain = getEnvironmentValue(REDIRECT_DOMAIN);
  const params = location.search.replace(
    /http.+syngentadigitalapps\.com/,
    redirectDomain
  );
  const newDomain = `${redirectDomain}${location.pathname}${params}`;

  useEffect(() => {
    setInterval(() => {
      setSeconds((secondsLeft) =>
        secondsLeft > 0 ? secondsLeft - 1 : secondsLeft
      );
    }, 1000);
  }, []);

  if (seconds === 0) {
    window.location.replace(newDomain);
  }

  return (
    <section className="maintenance">
      <img className="logo" src={logo} alt="logo" />
      <h3>
        <p className="bold">
          The Golden Advantage site has moved to{' '}
          <a href={newDomain}>goldenadvantage.com</a>
        </p>
        <p>Please update your bookmarks and click the link above to proceed.</p>
        <p>You will be automatically redirected in {seconds} seconds.</p>
      </h3>
    </section>
  );
};
