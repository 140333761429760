import React from 'react';
import { trim, isPlainObject, isArray } from 'lodash';
import { List, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import download from 'downloadjs';
import { DEF_TIME_FORMAT, USER_TYPES } from '../constants/ApplicationConstants';
import {
  APP_GROWER_LOGIN,
  APP_SEED_ADVISOR_LOGIN,
  APP_SALES_REPRESENTATIVE_LOGIN,
  APP_ADMIN_LOGIN,
  CSA_LOGIN,
} from '../constants/RouteConstants';
import { Col, Grid, Row } from 'antd';
import { INACTIVE, GHX_INACTIVE } from '../../enums/userStatuses';
import { isStringFalsyOrWhitespace } from './stringUtils';

function formatCurrencyValue(value) {
  return `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function parseCurrencyValue(value) {
  return value.replace(/\$\s?|(,*)/g, '');
}

const getYearList = (num, startYear) => {
  const currentYear = startYear;
  let years = [],
    i = currentYear;
  do {
    years.push(i);
    i--;
  } while (currentYear - i < num);
  return years;
};

const getFormattedMsg = (msgObj) => msgObj && <FormattedMessage {...msgObj} />;

const keyValueArrayToObj = (arrObj) =>
  (arrObj || []).reduce((lang, el) => lang.set(el.key, el.value), Map());

const isRequired = (rules) =>
  !(rules || []).every((rule) => rule.type !== 'REQUIRED');

const isRegExp = (rules) =>
  !(rules || []).every((rule) => rule.type !== 'REGEX');

const trimValues = (inpMap) => {
  return inpMap.withMutations((mutedMap) => {
    mutedMap.forEach((value, key) => {
      if (!(isArray(value) || isPlainObject(value) || List.isList(value))) {
        mutedMap.set(key, trim(value));
      }
    });
  });
};

const queryParams = () => new URLSearchParams(window.location.search);

const appRegexCollection = {
  password: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,})$/,
  zipCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  taxId: /^\d{9}$/,
  ssn: /^\d{9}$/,
  growerId: /(^00\d{8}$)|(^\d{8}$)/,
  integerOnly: /^\d+$/,
  positiveNumber: /^[+]?\d+([.]\d+)?$/,
  name: /^[ a-zA-Z\-\']+$/,
  middleinitial: /^[a-zA-Z]+$/,
};

//don't use === here.
// eslint-disable-next-line eqeqeq
const isInt = (n) => Number(n) == n && n % 1 === 0;

const isDigit = (n) => {
  const reg = /^-?\d+\.?\d*$/;
  return reg.test(n);
};
const stringPhoneToNumber = (value) => {
  return Number(value.replace(/[()-\s]/g));
};

const normalizePhone = (value, previousValue, readOnlyValues) => {
  if (!value) {
    return value;
  }
  if (value.indexOf('*') > -1 && readOnlyValues !== true) {
    return '';
  }
  const onlyNums =
    readOnlyValues === true ? value : value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return '(' + onlyNums + ') ';
    }
    if (onlyNums.length === 6) {
      return '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3);
  }
  return (
    '(' +
    onlyNums.slice(0, 3) +
    ') ' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
};

const getFormattedCurrency = (value) => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const getFormattedDate = (inDate, format = DEF_TIME_FORMAT, options = {}) => {
  if (options.useUtcTimezone) {
    return moment.utc(inDate).format(format);
  }
  return new moment(new Date(inDate)).format(format);
};

const getFormattedDateWithDefault = (inDate, options = {}) => {
  if (!inDate) {
    return '-';
  }
  return getFormattedDate(inDate, DEF_TIME_FORMAT, options);
};

const getUserTypeFromUrl = (defaultType) => {
  if (
    window.location.href.startsWith(`${window.location.origin}/seed-advisor/`)
  ) {
    return USER_TYPES.SEED_ADVISOR;
  } else if (
    window.location.href.startsWith(
      `${window.location.origin}/sales-representative/`
    )
  ) {
    return USER_TYPES.SALES_REPRESENTATIVE;
  } else if (
    window.location.href.startsWith(`${window.location.origin}/grower/`)
  ) {
    return USER_TYPES.GROWER;
  } else if (
    window.location.href.startsWith(`${window.location.origin}/administrator/`)
  ) {
    return USER_TYPES.ADMIN;
  } else if (
    window.location.href.startsWith(
      `${window.location.origin}/customer-support`
    )
  ) {
    return USER_TYPES.CUST_SUPPORT_ADMIN;
  }
  return defaultType || USER_TYPES.GROWER;
};

const getUserTypeLoginUrl = () => {
  return getUserTypeLogoutUrl();
};

const getUserTypeLogoutUrl = () => {
  switch (getUserTypeFromUrl()) {
    case USER_TYPES.SEED_ADVISOR:
      return APP_SEED_ADVISOR_LOGIN;
    case USER_TYPES.SALES_REPRESENTATIVE:
      return APP_SALES_REPRESENTATIVE_LOGIN;
    case USER_TYPES.ADMIN:
      return APP_ADMIN_LOGIN;
    case USER_TYPES.CUST_SUPPORT_ADMIN:
      return CSA_LOGIN;
    default:
      return APP_GROWER_LOGIN;
  }
};

const getDateRange = (fromDate, monthMinus) => {
  const toDate = new moment(fromDate).subtract(monthMinus, 'months').toDate();
  return new Date(toDate.getFullYear(), toDate.getMonth(), 1);
};

const downloadFilesOnClick = (urls) => {
  (urls || []).forEach((file) => {
    download(file.url);
  });
};

const getCurrentYearCreditHistory = (user) => {
  const { grower_credit_histories } = user || {};
  const currYear = getCurrentCropYear();
  return (
    (grower_credit_histories || []).find(
      (creHist) => Number(creHist.year) === Number(currYear)
    ) || {}
  );
};

const getCreditHistoryForYear = (creditHistories, year) => {
  return (creditHistories || []).find(
    (creditHistory) => creditHistory.year === year
  );
};

const getSelectedCropYear = () => {
  const selectedCropSeason = JSON.parse(
    sessionStorage.getItem('CropYearSelected')
  );
  return selectedCropSeason;
};

const getCurrentCropYear = () => {
  const { currentCropSeason } = JSON.parse(sessionStorage.getItem('userInfo'));
  return currentCropSeason;
};

const corpSeasonYearOptions = [2024, 2023, 2022, 2021, 2020];

const isUatOrTestOrLocalEnv = () => {
  console.log('env-->>', process.env.NODE_ENV);
  let getCurrentDate = new Date();
  let enableSeasonDateUAT = new Date('2024-07-08 0:00:00');
  let enableSeasonDatePROD = new Date('2024-08-01 0:00:00');

  const checkHostName =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('test') ||
    (getCurrentDate > enableSeasonDateUAT &&
      window.location.hostname.includes('uat')) ||
    (getCurrentDate > enableSeasonDatePROD &&
      window.location.hostname.includes('goldenadvantage'));

  return (
    process.env.NODE_ENV === 'test' ||
    process.env.NODE_ENV === 'local' ||
    process.env.NODE_ENV === 'development' ||
    checkHostName
  );
};

const dateSorter = (a, b, sortOrder, key) => {
  if (sortOrder === 'ascend') {
    if (!a[key]) return 1;
    if (!b[key]) return -1;
  } else {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
  }
  if (a[key] === b[key]) return 0;
  return moment(a[key]).unix() - moment(b[key]).unix();
};

const stringSorter = (a, b, sortOrder, key, locale) => {
  if (sortOrder === 'ascend') {
    if (!a[key]) return 1;
    if (!b[key]) return -1;
  } else {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
  }
  if (a[key] === b[key]) return 0;
  return new Intl.Collator(locale).compare(a[key].trim(), b[key].trim());
};

const maskWithAsterisk = (value, type) => {
  const regex = /(?!^)[\s\S](?!$)/g;
  let formattedValue;
  if (value && value !== null && trim(value) !== '') {
    if (type === 'email') {
      formattedValue = value
        .split('@')[0]
        .replace(regex, '*')
        .concat('@')
        .concat(value.split('@')[1]);
    }
    if (type === 'phone') {
      if (unformatPhoneNumber(value) === undefined) {
        return '';
      }
      formattedValue = unformatPhoneNumber(value)
        .toString()
        .replace(regex, '*');
    }
    return formattedValue;
  }
  return value;
};

const unformatPhoneNumber = (value) => {
  if (value) {
    return Number(trim(value).replace(/[()-\s]/g, '')) || undefined; //if it is a string, return undefined instead of NAN
  }
  return value;
};

const numberSorter = (a, b, sortOrder, key) => {
  if (sortOrder === 'ascend') {
    if (a[key] === undefined) return 1;
    if (b[key] === undefined) return -1;
  } else {
    if (a[key] === undefined) return -1;
    if (b[key] === undefined) return 1;
  }
  if (a[key] === b[key]) return 0;
  return a[key] < b[key] ? -1 : 1;
};

const getFullName = (firstName, middleName, lastName) => {
  return [firstName || '', middleName || '', lastName || '']
    .filter((el) => trim(el))
    .join(' ');
};

const isDisabledByDate = (settingEffectiveDate) => {
  const todayTimestamp = new Date().getTime();
  const timeStamp = settingEffectiveDate
    ? new Date(settingEffectiveDate).getTime()
    : todayTimestamp;
  return todayTimestamp >= timeStamp;
};

const getAcknowledgementsDisabled = (settings) => {
  const isSetForDisabling = settings && settings.disableAcknowledgements;
  if (isSetForDisabling && settings.disableAcknowledgementsDueDate) {
    return isDisabledByDate(settings.disableAcknowledgementsDueDate);
  }
  return isSetForDisabling;
};

const getGrowerInviteDisabled = (settings) => {
  const isSetForDisabling = settings && settings.disableGrowerInvite;
  if (isSetForDisabling && settings.disableGrowerInviteDueDate) {
    return isDisabledByDate(settings.disableGrowerInviteDueDate);
  }
  return isSetForDisabling;
};

const dataLayer = window.dataLayer || [];
const isMobileView = () => {
  const screens = Grid.useBreakpoint(); // eslint-disable-line react-hooks/rules-of-hooks

  return Boolean(
    Object.entries(screens).find((size) => size[0] === 'xs' && size[1] === true)
  );
};

const getExpandableRow = (data) => {
  return (
    <Row>
      {data.map((record) => {
        return (
          <Col xs={24} lg={12} id={data.key}>
            <Row>
              <Col xs={24} sm={12}>
                <strong>{record.label}:</strong>
              </Col>
              <Col xs={24} sm={12}>
                {record.value}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

const getDataWithUniqueKey = (data) =>
  data &&
  data.map((record) => ({
    key: record.id ? record.id : record.uuid,
    ...record,
  }));

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const deleteCookie = (cname) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=;' + expires + ';path=/';
};

const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const b64toBlob = (base64, type = 'application/pdf') =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

const bytesToArrayBuffer = (file_bytes, callBack) => {
  b64toBlob(file_bytes).then((resourceBlob) => {
    new Response(resourceBlob)
      .arrayBuffer()
      .then((arrayBuffer) => callBack(arrayBuffer));
  });
};

const isSeedAdvisorGhxInactive = (seedAdvInfo) => {
  return seedAdvInfo.status === GHX_INACTIVE;
};

const isSeedAdvisorInActive = (seedAdvInfo) => {
  return (
    seedAdvInfo.status === INACTIVE || isSeedAdvisorGhxInactive(seedAdvInfo)
  );
};

const isMissingRequiredParams = (params, reqParam) => {
  return !params.some((nameAndValuePair) => {
    const name = nameAndValuePair[0];
    const value = nameAndValuePair[1];
    return !isStringFalsyOrWhitespace(value) && reqParam.has(name);
  });
};

const isGrowerInactive = (grower) => {
  return !!grower.status && grower.status.toLowerCase() === 'inactive';
};

const invitationLogic = (grower) => {
  if (grower) {
    if (grower?.grower_status === 'Active' && grower.status === 'Active') {
      return true;
    } else if (
      grower?.grower_obj?.status === 'Active' &&
      grower.status === 'Active'
    ) {
      return true;
    }
    return false;
  }
};

export {
  getFormattedMsg,
  isRequired,
  isRegExp,
  isMobileView,
  trimValues,
  isInt,
  queryParams,
  appRegexCollection,
  normalizePhone,
  stringPhoneToNumber,
  keyValueArrayToObj,
  getFormattedCurrency,
  getFormattedDate,
  getFormattedDateWithDefault,
  getUserTypeFromUrl,
  getDateRange,
  downloadFilesOnClick,
  getUserTypeLogoutUrl,
  getUserTypeLoginUrl,
  getCurrentYearCreditHistory,
  getCreditHistoryForYear,
  getCurrentCropYear,
  stringSorter,
  numberSorter,
  dateSorter,
  maskWithAsterisk,
  getFullName,
  dataLayer,
  formatCurrencyValue,
  parseCurrencyValue,
  getYearList,
  getAcknowledgementsDisabled,
  getGrowerInviteDisabled,
  getExpandableRow,
  getDataWithUniqueKey,
  setCookie,
  deleteCookie,
  getCookie,
  bytesToArrayBuffer,
  isSeedAdvisorInActive,
  isSeedAdvisorGhxInactive,
  isDigit,
  isMissingRequiredParams,
  isGrowerInactive,
  invitationLogic,
  isUatOrTestOrLocalEnv,
  getSelectedCropYear,
  corpSeasonYearOptions,
};
