import { trim } from 'lodash';
import {
  getAvailableCredit,
  getPaymentDue,
} from '../../utilities/creditCalculations';

export const checkProfileComplete = (growerData) => {
  return !!(
    growerData &&
    trim(growerData.first_name).length &&
    trim(growerData.last_name).length &&
    trim(growerData.farm_name).length &&
    trim(growerData.grower_address).length &&
    trim(growerData.grower_city).length &&
    trim(growerData.grower_state).length &&
    trim(growerData.grower_zip).length &&
    trim(growerData.phone_number_mobile).length &&
    (growerData.account_updates_by_email ||
      growerData.account_updates_by_sms) &&
    (growerData.monthly_statements_by_email ||
      growerData.monthly_statements_by_mail) &&
    (growerData.payment_by_sa || growerData.payment_by_grower)
  );
};

export const checkSeedAdvisorSelected = (growerData) => {
  return growerData && growerData.primary_seed_adviser;
};

export const getCreditDetOfGrower = (creditHistObjects, year) => {
  const creditHistObj =
    (creditHistObjects || []).find(
      (creHist) => Number(creHist.year) === Number(year)
    ) || {};
  const {
    last_payment_date,
    payment_amount,
    next_payment_date,
    credit_line_approved,
    credit_line_used,
    total_treatment_fee,
    total_treatment_fee_primary_sa,
    total_treatment_fee_secondary_sa,
    application_status,
    decision_date,
    application_id,
    request_history,
  } = creditHistObj;
  const balanceAmount = getAvailableCredit(creditHistObj);
  const isPaymentDone = payment_amount < 0 ? -payment_amount : payment_amount;
  const paymentDue = getPaymentDue(creditHistObj);

  return {
    last_payment_date,
    next_payment_date,
    credit_line_approved:
      credit_line_approved && credit_line_approved >= 0
        ? credit_line_approved
        : '',
    payment_amount: isPaymentDone ? isPaymentDone : '',
    credit_line_used:
      credit_line_used && credit_line_used >= 0 ? credit_line_used : '',
    balanceAmount,
    paymentDue,
    total_treatment_fee: total_treatment_fee > 0 ? total_treatment_fee : '',
    application_status,
    decision_date,
    total_treatment_fee_primary_sa,
    total_treatment_fee_secondary_sa,
    application_id,
    request_history,
  };
};

export const getSAName = (seedAdv) => {
  return seedAdv ? seedAdv.operation_name : '';
};

export const getSAFullName = (seedAdv) => {
  let getSAFullName = '';
  if (seedAdv) {
    getSAFullName = `${seedAdv.first_name} ${seedAdv.last_name}`;
  }
  return getSAFullName;
};

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName || {}).length === 0;
};
