import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from 'loadable-components';
import { Helmet } from 'react-helmet';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Maintenance } from '../maintenance/Maintenance';
import {
  APP_GROWER_LOGIN,
  APP_SEED_ADVISOR_LOGIN,
  APP_ADMIN_LOGIN,
  CSA_LOGIN,
  CREATE_GROWER_ACCOUNT,
  OKTA_IMPLICIT_CALLBACK,
  VERIFY_ACCOUNT,
  RESET_SEED_ADVISOR_PASSWORD,
  RESET_GROWER_PASSWORD,
  GROWER_FORGOT_PASSWORD,
  SA_FORGOT_PASSWORD,
  APP_SALES_REPRESENTATIVE_LOGIN,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  SHOW_PDF,
  SA_SETUP_ACCOUNT,
} from '../constants/RouteConstants';
import { USER_TYPES } from '../constants/ApplicationConstants';
import Landing from './LandingContainer';
import { ConfigProvider } from 'antd';
import en_US from 'antd/es/locale/en_US';
import Login from '../login';
// import PrivacyPolicy from '../common/CookieConsent/PrivacyPolicy';
// import CookiePolicy from '../common/CookieConsent/CookiePolicy';
import LinkablePdf from '../common/pdf/linkable-pdf';
import { OktaError } from '../security';

import { getUserTypeFromUrl } from '../utilities/UtilityFunctions';
import { RedirectToNewDomain } from '../maintenance/RedirectToNewDomain';
import {
  getEnvironmentValue,
  OKTA_AUTH_SERVER,
  OKTA_CLIENT_ID,
  OKTA_DOMAIN,
  REDIRECT_TO_NEW_DOMAIN,
} from '../../envConfig';
import { isGaUnderMaintenance } from '../maintenance/maintenanceUtils';
import { OktaAuth } from '@okta/okta-auth-js';

const CreateAccount = loadable(() => import('../create-account'));
const VerifyAccount = loadable(() => import('../security/verify-account'));
const ResetPassword = loadable(() => import('../reset-password'));
const ForgotPassword = loadable(() => import('../forgot-password'));
const SASetupAccount = loadable(() => import('../seed-advisor/setup-account'));

const oktaAuth = new OktaAuth({
  issuer: `${getEnvironmentValue(OKTA_DOMAIN)}/oauth2/${getEnvironmentValue(
    OKTA_AUTH_SERVER
  )}`,
  clientId: getEnvironmentValue(OKTA_CLIENT_ID),
  redirectUri: `${window.location.origin}${OKTA_IMPLICIT_CALLBACK}`,
  pkce: true,
});

const App = ({ messages, history, ...props }) => {
  const onAuthRequired = () => {
    const userType = getUserTypeFromUrl();
    if (USER_TYPES.GROWER === userType) {
      history.push(APP_GROWER_LOGIN);
    } else if (USER_TYPES.SEED_ADVISOR === userType) {
      history.push(APP_SEED_ADVISOR_LOGIN);
    } else if (USER_TYPES.ADMIN === userType) {
      history.push(APP_ADMIN_LOGIN);
    } else if (USER_TYPES.SALES_REPRESENTATIVE === userType) {
      history.push(APP_SALES_REPRESENTATIVE_LOGIN);
    } else if (USER_TYPES.CUST_SUPPORT_ADMIN === userType) {
      history.push(CSA_LOGIN);
    }
  };
  const showRedirectToNewDomain =
    getEnvironmentValue(REDIRECT_TO_NEW_DOMAIN) === 'true' &&
    window.location.host.includes('syngentadigitalapps');
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [maintenanceEndTime, setMaintenanceEndTime] = useState(undefined);
  const onCompleteGettingSettings = (settingsResponse) => {
    if (isGaUnderMaintenance(settingsResponse)) {
      setIsUnderMaintenance(true);
      setMaintenanceEndTime(settingsResponse.maintenanceEndTime);
    }
  };

  useEffect(() => {
    props.getSettings(onCompleteGettingSettings);
  }, []);

  return (
    <React.Fragment>
      <Helmet title={messages.application_title} />
      <ConfigProvider locale={en_US}>
        <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
          <Switch>
            {isUnderMaintenance && (
              <Route
                path="/"
                component={() => <Maintenance endTime={maintenanceEndTime} />}
              />
            )}
            {showRedirectToNewDomain && (
              <Route path="/" component={RedirectToNewDomain} />
            )}
            <Route exact path={APP_GROWER_LOGIN} component={Login} />
            <Route exact path={APP_SEED_ADVISOR_LOGIN} component={Login} />
            <Route exact path={APP_ADMIN_LOGIN} component={Login} />
            <Route
              exact
              path={APP_SALES_REPRESENTATIVE_LOGIN}
              component={Login}
            />
            <Route exact path={CSA_LOGIN} component={Login} />

            <Route exact path={VERIFY_ACCOUNT} component={VerifyAccount} />

            <Route
              exact
              path={CREATE_GROWER_ACCOUNT}
              component={CreateAccount}
            />
            <Route
              exact
              path={RESET_GROWER_PASSWORD}
              component={ResetPassword}
            />
            <Route
              exact
              path={RESET_SEED_ADVISOR_PASSWORD}
              component={ResetPassword}
            />
            <Route
              exact
              path={GROWER_FORGOT_PASSWORD}
              component={ForgotPassword}
            />
            <Route exact path={SA_FORGOT_PASSWORD} component={ForgotPassword} />
            <Route
              exact
              path={PRIVACY_POLICY}
              render={() =>
                (window.location =
                  'https://www.syngenta-us.com/legal/privacypolicy.html')
              }
            />
            <Route
              exact
              path={COOKIE_POLICY}
              render={() =>
                (window.location =
                  'https://www.syngenta-us.com/legal/cookiepolicy.html')
              }
            />
            {/* <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={COOKIE_POLICY} component={CookiePolicy} /> */}
            <Route exact path={SHOW_PDF} component={LinkablePdf} />
            <Route
              path={OKTA_IMPLICIT_CALLBACK}
              component={() => <LoginCallback errorComponent={OktaError} />}
            />
            <Route exact path={SA_SETUP_ACCOUNT} component={SASetupAccount} />
            <SecureRoute path="/" component={Landing} />
          </Switch>
        </Security>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default App;
