import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import './landing.less';

// Define a constant for the URL of an API endpoint

export default function NoticeModal(props) {
  const {
    showModal,
    handleClose,
    handlepolicyClick,
    agreeClk,
    policyClk,
    handleagrrementClick,
  } = props;

  // const [agreeClk, setagreeClk] = useState(false);
  // const [policyClk, setpolicyClk] = useState(false);
  // const policyClick = () => {
  //   setpolicyClk(true);
  //   window.open(
  //     'https://www.syngenta-us.com/legal/privacypolicy.html',
  //     '_blank'
  //   );
  // };

  // const agrrementClick = () => {
  //   setagreeClk(true);
  //   window.open(
  //     'https://www.syngenta-us.com/legal/useragreement.html',
  //     '_blank'
  //   );
  // };

  return (
    <Modal
      centered
      visible={showModal}
      closable={false}
      title={<h1> Welcome back! </h1>}
      footer={null}
      maskClosable={false}
      className="idle-modal"
    >
      <>
        <Row gutter={[16, 16]}>
          <Col className="idle-modal-body">
            {' '}
            <i>
              We want to let you know that we recently updated our
              <button class="like-anchor" onClick={handlepolicyClick}>
                Privacy Policy
              </button>
              and want to remind you of our{' '}
              <button class="like-anchor" onClick={handleagrrementClick}>
                User Agreement
              </button>
              applicable to your use of this website. We encourage you to review
              these agreements in full.Your continued use and access of this
              website constitutes your acceptance of this Privacy Policy and
              User Agreement
            </i>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              key="stay"
              disabled={!agreeClk || !policyClk}
              onClick={handleClose}
            >
              I accept
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col>
            <i>Please click the policy links above to accept.*</i>
          </Col>
        </Row>
      </>
    </Modal>
  );
}
