import React from 'react';
import logo from '../../client/images/GH_GoldenAdvantage_Logo_RGB.png';
import './maintenance.less';

function getMaintenanceMessage(endTime) {
  if (!endTime)
    return 'The Golden Advantage site is currently down for maintenance.';

  const endTimeDate = new Date(endTime);
  const time =
    endTimeDate.toLocaleDateString() + ' ' + endTimeDate.toLocaleTimeString();
  return `The Golden Advantage site is currently down for scheduled maintenance until ${time}.`;
}

export const Maintenance = ({ endTime }) => {
  return (
    <section className="maintenance">
      <img className="logo" src={logo} alt="logo"></img>
      <h3>
        <p className="bold">{getMaintenanceMessage(endTime)}</p>
        <p>
          We apologize for any inconvenience this may cause. If you have
          questions; please contact Golden Advantage support at (855) 939-0058
          or at&nbsp;
          <a href="mailto:support@goldenadvantage.com">
            support@goldenadvantage.com
          </a>
        </p>
      </h3>
    </section>
  );
};
