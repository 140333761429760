import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSalesYearState = (state) => state.get('salesYear', initialState);
const getSelectedSalesYear = createSelector(
  selectSalesYearState,
  (salesYearState) => {
    return salesYearState.get('salesYear');
  }
);

export { getSelectedSalesYear };
