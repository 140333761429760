export const DEFAULT_LOCALE = 'en';
export const COMMON_LOCALE = 'def';
export const LOGOUT_ACTION = 'LOGOUT_USER';
export const SET_SESSION_ACTION = 'SET_SESSION';

export const DEF_TIME_FORMAT = 'MM/DD/YYYY';

export const FIELDS_TYPE = {
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  NUMBER: 'NUMBER',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  ARRAY_OF_OBJECTS: 'ARRAY_OF_OBJECTS',
};

export const HTTP_ERROR_CODES = {
  SERVER_ERROR: 500,
  BAD_REQUEST: 400,
};

export const APPLICATION_STATUS = {
  INVITE_OR_REMIND: 'Invite / Remind',
  INVITE: 'Invite',
  REMIND: 'Remind',
};

export const USER_TYPES = {
  GROWER: 'GROWER',
  SEED_ADVISOR: 'SEED_ADVISOR',
  SALES_REPRESENTATIVE: 'SALES_REPRESENTATIVE',
  ADMIN: 'ADMINISTRATOR',
  CUST_SUPPORT_ADMIN: 'CUSTOMER_SUPPORT',
};

export const SORT_TYPE = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  DATE: 'DATE',
};

export const FILTER_TYPE = {
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  DATE: 'DATE',
};

export const SIDE_BAR_RESIZE_MIN_WIDTH = 1100;

export const NO_PAYMENT_PREFERENCES = 'No Payment Preferences';

export const SET_GROWER_INFO = 'SET_GROWER_INFO';
export const CREDIT_LINE_APPLICATION_STATUS = {
  PENDING: 'pending',
};

export const DATE_FORMAT = 'MM/DD/YYYY';
export const SEED_ADV_BUDGET_ALLOC_MAX = 100;

export const CROP_SEASON_STARTED_YEAR = 2020;
