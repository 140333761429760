import { getEnvironmentValue, TIMEOUT_IN_MINUTES } from '../../envConfig';

const hasSyngentaEmail = (email) =>
  new RegExp('@syngenta.com$', 'i').test(email);
const convertMinutesToMillis = (minutes) => minutes * 60 * 1000;

export function getUserIdleTimeoutDuration(sessionEmail) {
  const DEFAULT_TIMEOUT = 3;
  const DEFAULT_INTERNAL_USER_TIMEOUT = 10;
  const isInternalUser = sessionEmail && hasSyngentaEmail(sessionEmail);
  const environmentTimeout =
    getEnvironmentValue(TIMEOUT_IN_MINUTES) || DEFAULT_TIMEOUT;
  const internalUserTimeout =
    DEFAULT_INTERNAL_USER_TIMEOUT > environmentTimeout
      ? DEFAULT_INTERNAL_USER_TIMEOUT
      : environmentTimeout;
  const timeoutInMinutes = isInternalUser
    ? internalUserTimeout
    : environmentTimeout;

  return convertMinutesToMillis(timeoutInMinutes);
}
