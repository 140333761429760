import { fromJS, Map } from 'immutable';
import { isPlainObject } from 'lodash';

import { SET_DROPDOWN_VALUE } from './actions';
import { keyValueArrayToObj } from '../../utilities/UtilityFunctions';

export const initialState = fromJS({
  dropdown: Map(),
});

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DROPDOWN_VALUE:
      let value;
      if (isPlainObject(action.value)) {
        value = Map(action.value);
      } else {
        value = Map.isMap(action.value)
          ? action.value
          : keyValueArrayToObj(action.value);
      }
      let currDrop = state.get('dropdown');
      let nextlocaleMap = currDrop
        .get(action.locale, Map())
        .set(action.name, value);
      return state.set('dropdown', currDrop.set(action.locale, nextlocaleMap));
    default:
      return state;
  }
};

export default commonReducer;
