import React from 'react';
import { Form, InputNumber } from 'antd';
import { isRequired } from '../../utilities/UtilityFunctions';

const formatter = {
  dollar: (value) =>
    `$ ${
      value.indexOf('.') >= 0
        ? value.substr(0, value.indexOf('.')) +
          value.substr(value.indexOf('.'), 3)
        : value
    }`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  percent: (value) => `${value}%`,
};

const parser = {
  dollar: (value) => value.replace(/\$\s?|(,*)/g, ''),
  percent: (value) => value.replace('%', ''),
};

const NumberField = ({
  input,
  type,
  placeholder,
  label,
  disabled,
  meta,
  rules,
  ownValue,
  readOnly,
  customizedClass,
  formatterType,
  max,
  min,
  precision,
  step,
}) => {
  let customClass = meta.error ? 'ant-form-item-has-error' : '';
  if (isRequired(rules)) {
    customClass = `${customClass} item-required`;
  }
  return (
    <Form.Item labelCol={{ span: 24 }} label={label} className={customClass}>
      {!readOnly ? (
        <InputNumber
          onChange={input.onChange}
          value={ownValue || input.value}
          disabled={disabled}
          placeholder={placeholder || ''}
          className={customizedClass}
          min={min}
          max={max}
          formatter={formatterType && formatter[formatterType]}
          parser={formatterType && parser[formatterType]}
          precision={precision}
          step={step}
        />
      ) : (
        <div style={{ height: '32px' }}>
          <b style={{ fontSize: '20px' }}>{ownValue || input.value}</b>
        </div>
      )}
      {meta.error && <div className="ant-form-item-explain">{meta.error}</div>}
    </Form.Item>
  );
};

export { NumberField };
