const GA_SERVICE = 'GA_SERVICE';
const OKTA_DOMAIN = 'OKTA_DOMAIN';
const OKTA_AUTH_SERVER = 'OKTA_AUTH_SERVER';
const OKTA_CLIENT_ID = 'OKTA_CLIENT_ID';
const PAYMENT_REDIRECT_URL = 'PAYMENT_REDIRECT_URL';
const REDIRECT_TO_NEW_DOMAIN = 'REDIRECT_TO_NEW_DOMAIN';
const REDIRECT_DOMAIN = 'REDIRECT_DOMAIN';
const TIMEOUT_IN_MINUTES = 'TIMEOUT_IN_MINUTES';
const SALES_YEAR_CONFIG = true;
const SMARTLOOK_TRACKING = 'SMARTLOOK_TRACKING';

const ENV_CONFIGURATIONS = {
  localhost: {
    [GA_SERVICE]: 'http://localhost:1337',
    [OKTA_DOMAIN]: 'https://signin-test.syngenta.com',
    [OKTA_AUTH_SERVER]: 'ausqmgapztkTfvQg70h7',
    [OKTA_CLIENT_ID]: '0oasj13a48D02XxbT0h7',
    [PAYMENT_REDIRECT_URL]: 'http://localhost:3000/grower/payment',
    [REDIRECT_TO_NEW_DOMAIN]: 'true',
    [REDIRECT_DOMAIN]: 'https://dev.goldenadvantage.com',
    [TIMEOUT_IN_MINUTES]: 15,
    [SALES_YEAR_CONFIG]: true,
    [SMARTLOOK_TRACKING]: false,
  },
  dev: {
    [GA_SERVICE]: 'https://dev.golden-advantage.syngentadigitalapps.com',
    [OKTA_DOMAIN]: 'https://signin-test.syngenta.com',
    [OKTA_AUTH_SERVER]: 'ausqmgapztkTfvQg70h7',
    [OKTA_CLIENT_ID]: '0oasj3kmlmFSvO3A70h7',
    [PAYMENT_REDIRECT_URL]: 'https://dev.goldenadvantage.com/grower/payment',
    [REDIRECT_TO_NEW_DOMAIN]: 'true',
    [REDIRECT_DOMAIN]: 'https://dev.goldenadvantage.com',
    [TIMEOUT_IN_MINUTES]: 3,
    [SALES_YEAR_CONFIG]: true,
    [SMARTLOOK_TRACKING]: false,
  },
  test: {
    [GA_SERVICE]: 'https://test.golden-advantage.syngentadigitalapps.com',
    [OKTA_DOMAIN]: 'https://signin-test.syngenta.com',
    [OKTA_AUTH_SERVER]: 'ausqmgapztkTfvQg70h7',
    [OKTA_CLIENT_ID]: '0oat0qhu5ojHv3TDf0h7',
    [PAYMENT_REDIRECT_URL]: 'https://test.goldenadvantage.com/grower/payment',
    [REDIRECT_TO_NEW_DOMAIN]: 'true',
    [REDIRECT_DOMAIN]: 'https://test.goldenadvantage.com',
    [TIMEOUT_IN_MINUTES]: 3,
    [SALES_YEAR_CONFIG]: true,
    [SMARTLOOK_TRACKING]: false,
  },
  uat: {
    [GA_SERVICE]: 'https://stage.golden-advantage.syngentadigitalapps.com',
    [OKTA_DOMAIN]: 'https://signin-test.syngenta.com',
    [OKTA_AUTH_SERVER]: 'ausqmgapztkTfvQg70h7',
    [OKTA_CLIENT_ID]: '0oaumkkg44xQHnhJd0h7',
    [PAYMENT_REDIRECT_URL]: 'https://uat.goldenadvantage.com/grower/payment',
    [REDIRECT_TO_NEW_DOMAIN]: 'true',
    [REDIRECT_DOMAIN]: 'https://uat.goldenadvantage.com',
    [TIMEOUT_IN_MINUTES]: 3,
    [SALES_YEAR_CONFIG]: true,
    [SMARTLOOK_TRACKING]: false,
  },
  prod: {
    [GA_SERVICE]: 'https://prod.golden-advantage.syngentadigitalapps.com',
    [OKTA_DOMAIN]: 'https://signin.syngenta.com',
    [OKTA_AUTH_SERVER]: 'aus575jkuuZAOVhr74x6',
    [OKTA_CLIENT_ID]: '0oapfdnqeV5zli12x4x6',
    [PAYMENT_REDIRECT_URL]: 'https://goldenadvantage.com/grower/payment',
    [REDIRECT_TO_NEW_DOMAIN]: 'true',
    [REDIRECT_DOMAIN]: 'https://goldenadvantage.com',
    [TIMEOUT_IN_MINUTES]: 3,
    [SALES_YEAR_CONFIG]: true,
    [SMARTLOOK_TRACKING]: true,
  },
};

function setEnvironmentConfiguration() {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return ENV_CONFIGURATIONS.localhost;
  } else if (hostname.includes('dev.goldenadvantage.com')) {
    return ENV_CONFIGURATIONS.dev;
  } else if (hostname.includes('test.goldenadvantage.com')) {
    return ENV_CONFIGURATIONS.test;
  } else if (hostname.includes('uat.goldenadvantage.com')) {
    return ENV_CONFIGURATIONS.uat;
  }
  return ENV_CONFIGURATIONS.prod; // prod hostname could be goldenadvantage.com or www.goldenadvantage.com
}

function getEnvironmentValue(key) {
  if (process.env.REACT_APP_USE_CONFIG !== 'true') {
    return process.env[`REACT_APP_${key}`];
  }

  if (!window.env) {
    window.env = setEnvironmentConfiguration();
  }
  return window.env[key];
}

export {
  getEnvironmentValue,
  GA_SERVICE,
  OKTA_DOMAIN,
  OKTA_AUTH_SERVER,
  OKTA_CLIENT_ID,
  PAYMENT_REDIRECT_URL,
  REDIRECT_TO_NEW_DOMAIN,
  REDIRECT_DOMAIN,
  TIMEOUT_IN_MINUTES,
  SALES_YEAR_CONFIG,
  SMARTLOOK_TRACKING,
};
