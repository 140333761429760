import * as route from './RouteConstants';

import dashboard from '../../client/images/dashboard.svg';
import statements from '../../client/images/account_balance_wallet.svg';
import makePayment from '../../client/images/attach_money.svg';
import applyForCredit from '../../client/images/credit_card.svg';
import resources from '../../client/images/support.svg';

import {
  DollarOutlined,
  MailOutlined,
  SolutionOutlined,
  TeamOutlined,
  SettingOutlined,
  FileSearchOutlined,
  RocketOutlined,
  FileImageOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons';

const allGrowers = () => SolutionOutlined;
const allSeedAdvisors = () => TeamOutlined;
const collections = () => DollarOutlined;

const menus = {
  grower: [
    {
      navLink: route.DASHBOARD,
      svg: dashboard,
      label: { id: 'src.components.Landing.menu.grower.dashboard' },
    },
    {
      navLink: route.STATEMENTS,
      svg: statements,
      label: { id: 'src.components.Landing.menu.grower.statements' },
    },
    {
      navLink: route.MAKE_PAYMENT,
      svg: makePayment,
      label: { id: 'src.components.Landing.menu.grower.make_payment' },
    },
    {
      isApplyForCredit: true,
      navLink: route.APPLY_FOR_CREDIT,
      svg: applyForCredit,
      label: {
        id: 'src.components.Landing.menu.grower.apply_for_credit',
      },
    },
    {
      navLink: route.RESOURCES,
      svg: resources,
      label: { id: 'src.components.App.Common.Landing.menu.resources' },
    },
  ],
  seed_advisor: [
    {
      navLink: route.SA_DASHBOARD,
      svg: dashboard,
      label: { id: 'src.components.Landing.menu.sa.dashboard' },
    },
    {
      navLink: route.SA_GROWERS,
      renderIcon: allGrowers,
      label: { id: 'src.components.Landing.menu.sa.growers' },
    },
    {
      navLink: route.SA_COLLECTIONS,
      renderIcon: collections,
      label: { id: 'src.components.collections.landing.menu.title' },
    },
    {
      navLink: route.SA_RESOURCES,
      svg: resources,
      label: { id: 'src.components.App.Common.Landing.menu.resources' },
    },
  ],
  sales_representative: [
    {
      navLink: route.SR_DASHBOARD,
      svg: dashboard,
      label: { id: 'src.components.Landing.menu.sales_rep.dashboard' },
    },
    {
      navLink: route.SR_GROWERS,
      renderIcon: allGrowers,
      label: { id: 'src.components.Landing.menu.sales_rep.growers' },
    },
    {
      navLink: route.SR_SEED_ADVISOR,
      renderIcon: allSeedAdvisors,
      label: {
        id: 'src.components.Landing.menu.sales_rep.seed_advisers',
      },
    },
    {
      navLink: route.SR_COLLECTIONS,
      renderIcon: collections,
      label: { id: 'src.components.collections.landing.menu.title' },
    },
    {
      navLink: route.SR_RESOURCES,
      svg: resources,
      label: { id: 'src.components.App.Common.Landing.menu.resources' },
    },
  ],
  admin: [
    {
      navLink: route.ADMIN_BULLETIN,
      svg: dashboard,
      label: { id: 'src.components.Landing.menu.admin.bulletin' },
    },
    {
      navLink: route.ADMIN_RESOURCES,
      svg: resources,
      label: { id: 'src.components.App.Common.Landing.menu.resources' },
    },
    {
      navLink: route.ADMIN_GROWER_NOTIFICATIONS,
      renderIcon: () => MailOutlined,
      label: {
        id: 'src.components.Landing.menu.admin.grower_notifications',
      },
    },
    {
      navLink: route.ADMIN_STATEMENTS,
      svg: statements,
      label: { id: 'src.components.Landing.menu.grower.statements' },
    },
    {
      navLink: route.ADMIN_STATEMENT_MARKETING_ADS,
      renderIcon: () => FileImageOutlined,
      label: {
        id: 'src.components.Landing.menu.admin.statement_marketing_ads',
      },
    },
    {
      navLink: route.ADMIN_SETTINGS,
      renderIcon: () => SettingOutlined,
      label: { id: 'src.components.Landing.menu.admin.settings' },
    },
    {
      navLink: route.ADMIN_BATCH_JOBS,
      renderIcon: () => RocketOutlined,
      label: { id: 'src.components.Landing.menu.admin.batch_jobs' },
    },
    {
      navLink: route.ADMIN_PAYMENT_SYNC,
      renderIcon: () => CloudSyncOutlined,
      label: { id: 'src.components.Landing.menu.admin.payment_sync' },
    },
  ],
  customerSupport: [
    {
      navLink: route.CSA_DASHBOARD,
      svg: dashboard,
      label: { id: 'src.components.Landing.menu.csa.dashboard' },
    },
    {
      navLink: route.CSA_ALL_GROWER,
      renderIcon: allGrowers,
      label: { id: 'src.components.Landing.menu.csa.all_grower' },
    },
    {
      navLink: route.CSA_ALL_SA,
      renderIcon: allSeedAdvisors,
      label: { id: 'src.components.Landing.menu.csa.all_sa' },
    },
    {
      navLink: route.CSA_COLLECTIONS,
      renderIcon: collections,
      label: { id: 'src.components.collections.landing.menu.title' },
    },
    {
      navLink: route.CSA_RESOURCES,
      svg: resources,
      label: { id: 'src.components.App.Common.Landing.menu.resources' },
    },
    {
      navLink: route.CSA_SEARCH,
      renderIcon: () => FileSearchOutlined,
      label: { id: 'src.components.Landing.menu.csa.search' },
    },
  ],
};
export default menus;
