import { fromJS } from 'immutable';
import { SET_SELECTED_SALES_GROUP } from './action';

export const initialState = fromJS({
  selectedSalesGroup: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SALES_GROUP:
      return state.set('selectedSalesGroup', action.salesGroup);
    default:
      return state;
  }
};
