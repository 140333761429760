import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';

const RadioComponent = ({
  nameOne,
  nameTwo,
  valueOne,
  valueTwo,
  defaultValue,
  sendValue,
  type,
  disabled,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue.payment_by_sa === true) {
      setValue('sa');
    } else if (defaultValue.payment_by_grower === true) {
      setValue('grower');
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (type === 'PAYMENT_PREFERENCES') {
      if (event.target.value === 'sa') {
        sendValue('payment_by_sa', true);
      } else {
        sendValue('payment_by_grower', true);
      }
    }
  };

  return (
    <Radio.Group onChange={handleChange} value={value} disabled={disabled}>
      <Radio value={'sa'} className="mt10 mb10" name={nameOne}>
        <b>{valueOne}</b>
      </Radio>
      <Radio value={'grower'} className="mb10" name={nameTwo}>
        <b>{valueTwo}</b>
      </Radio>
    </Radio.Group>
  );
};

export { RadioComponent };
