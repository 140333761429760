import React, { Component } from 'react';
import { startCase, lowerCase, upperCase, trim } from 'lodash';
import {
  Layout,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Avatar,
  Spin,
  Tooltip,
  Divider,
  Modal,
  Result,
} from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  BarsOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Menus } from './Menus';

import SideMenu from './SideMenu';
import ContentBody from './ContentBody';

import {
  GET_GROWER_PROFILE,
  SA_PROFILE,
  APP_HOME,
  SA_DASHBOARD,
  SR_DASHBOARD,
  DASHBOARD,
  ADMIN_BULLETIN,
  CSA_DASHBOARD,
  SR_PROFILE,
  CSA_PROFILE,
  APP_GROWER_LOGIN,
} from '../constants/RouteConstants';
import { USER_TYPES } from '../constants/ApplicationConstants';
//import { applicationSteps } from '../grower/profileAndApplication/utilities';
import {
  getUserTypeFromUrl,
  dataLayer,
  isGrowerInactive,
} from '../utilities/UtilityFunctions';
import FinancialYearSelector from '../shared/components/financialYearSelector/FinancialYearSelectorContainer';
import logo from '../../client/images/logo-horizontal.png';
import notification from '../../client/images/notifications.svg';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import IdleTimer from 'react-idle-timer';
import IdleTimeOutModal from './IdleTimeOutModal';
import NoticeModal from './NoticeModal';
import './landing.less';
import { getEnvironmentValue, SALES_YEAR_CONFIG } from '../../envConfig';
import CookieConsent from '../common/CookieConsent/CookieConsent';
import CookieInfo from '../common/CookieConsent/CookieInfo';
import { getUserIdleTimeoutDuration } from './user-idle-timeout';
import { addUserIdentification } from '../utilities/smartlook';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.loadUserType = undefined;
    this.showNtc = false;
    this.state = {
      isSiderCollapsed: false,
      isHeaderMenuVisible: false,
      showModal: false,
      showNoticeModal: false,
      agreeClk: false,
      policyClk: false,
      saClicked: false,
      growerClicked: false,
      agreementLinkClicked: false,
      policyLinkClicked: false,
      userLoggedIn: false,
      isTimedOut: false,
      currentUsertype: 'user',
      showCookie: false,
      smallScreen: window.matchMedia('(max-width: 500px)').matches,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.openCookiePolicy = this.openCookiePolicy.bind(this);
    this.closeCookiePolicy = this.closeCookiePolicy.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNoticeClose = this.handleNoticeClose.bind(this);
    this.handlepolicyClick = this.handlepolicyClick.bind(this);
    this.handleagrrementClick = this.handleagrrementClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleAgreeLink = this.handleAgreeLink.bind(this);
    this.handlePolicyLink = this.handlePolicyLink.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const userTimedOut = this.state.isTimedOut;
    if (userTimedOut) {
      this.props.logoutUser();
      this.props.history.push(APP_GROWER_LOGIN);
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleNoticeClose() {
    this.setState({ showModal: false });
    // this.setState({ showNoticeModal: false });
    this.showNtc = false;
    const { updateNotificationStatus } = this.props;
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    userDetails['is_agreements_agreed'] = true;
    this.props.aggreeClick();

    if (userDetails.userType === 'SEED_ADVISOR') {
      this.setState({ saClicked: true });
    } else if (userDetails.userType === 'GROWER') {
      this.setState({ growerClicked: true });
    }
    if (userDetails.userType === 'GROWER' && userDetails.step !== undefined) {
      delete userDetails.step;
    }
    sessionStorage.setItem('userInfo', JSON.stringify(userDetails));
    this.setState({ agreeClk: false });
    this.setState({ policyClk: false });

    updateNotificationStatus(userDetails);
    setTimeout(() => {
      window.location.reload();
      // console.log('Delayed for 1 second reload.');
    }, '1000');
  }

  handleAgreeLink() {
    this.setState({ agreementLinkClicked: true });
  }

  handlepolicyClick() {
    this.setState({ policyClk: true });
    window.open(
      'https://www.syngenta-us.com/legal/privacypolicy.html',
      '_blank'
    );
  }

  handleagrrementClick() {
    this.setState({ agreeClk: true });
    window.open(
      'https://www.syngenta-us.com/legal/useragreement.html',
      '_blank'
    );
  }

  handlePolicyLink() {
    this.setState({ policyLinkClicked: true });
  }

  openCookiePolicy() {
    this.setState({ showCookie: true });
  }

  closeCookiePolicy() {
    this.setState({ showCookie: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.props.logoutUser();
    this.props.history.push(APP_GROWER_LOGIN);
  }

  async componentDidMount() {
    const handler = (e) => this.setState({ smallScreen: e.matches });
    const mediaWatcher = window.matchMedia('(max-width: 500px)');
    if (mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener('change', handler);
    } else {
      mediaWatcher.addListener(handler);
    }
    if (isEmpty(this.props.session)) {
      const session = await this.props.oktaAuth.getUser();
      if (session) {
        const accessToken = this.props.oktaAuth.getAccessToken();
        session.accessToken = accessToken;
        const { email } = session;
        addUserIdentification(email);
        this.props.setSession(session);
      } else {
        this.props.logoutUser();
      }
    } else {
      this.loadUserDetail();
    }
  }

  loadUserDetail = () => {
    this.props.loadUserDetail(
      this.props.sessionEmail,
      this.loadUserType || getUserTypeFromUrl(),
      window.localStorage.getItem('seed-advisor-id') || '',
      window.localStorage.getItem('zd-partnership-uuid')
    );
    this.loadUserType = undefined;
  };

  componentDidUpdate(prevProps) {
    const { user, menuItems, session, history, location } = this.props;

    if (
      user?.is_agreements_agreed !== undefined &&
      !user?.is_agreements_agreed
    ) {
      this.showNtc = true;
    }
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (
      userDetails?.is_agreements_agreed !== undefined &&
      !userDetails?.is_agreements_agreed
    ) {
      this.showNtc = true;
    } else {
      this.showNtc = false;
    }

    if (
      userDetails?.userType === 'GROWER' &&
      userDetails?.grower_seed_advisors?.length === 0
    ) {
      this.showNtc = false;
    }

    // if (
    //   userDetails?.userType === 'GROWER' &&
    //   userDetails?.grower_seed_advisors?.length > 0 &&
    //   userDetails.step !== undefined &&
    //   userDetails.step === applicationSteps.CONFIRMATION
    // ) {
    //   this.showNtc = true;
    // }

    if (
      session &&
      ((!user && !prevProps.session) || (prevProps.user && !user))
    ) {
      this.loadUserDetail();
    } else if (
      user &&
      menuItems.length > 0 &&
      APP_HOME === get(history, 'location.pathname', APP_HOME)
    ) {
      if (user.isProfileComplete) {
        history.push(menuItems[0].navLink);
      } else {
        let targetUrl;
        switch (user.userType) {
          case USER_TYPES.ADMIN:
            targetUrl = ADMIN_BULLETIN;
            break;
          case USER_TYPES.SEED_ADVISOR:
            targetUrl = SA_PROFILE;
            break;
          default:
            targetUrl = DASHBOARD;
            break;
        }
        history.push(targetUrl);
      }
    } else if (
      user &&
      menuItems.length &&
      location.pathname.indexOf(this.checkAccessURL()) === -1
    ) {
      history.push(menuItems[0].navLink);
    }
    dataLayer.push({
      event: 'PageView',
      path: '/landing',
      userType: user ? user.userType : 'session',
    });
  }

  getUserProfileURL = () => {
    const { user } = this.props;
    const returnURL =
      USER_TYPES.GROWER === user.userType
        ? GET_GROWER_PROFILE()
        : USER_TYPES.SALES_REPRESENTATIVE === user.userType
        ? SR_PROFILE
        : USER_TYPES.CUST_SUPPORT_ADMIN === user.userType
        ? CSA_PROFILE
        : USER_TYPES.SEED_ADVISOR === user.userType
        ? SA_PROFILE
        : CSA_PROFILE;
    return returnURL;
  };
  navigateToProfile = () => {
    const { history } = this.props;
    const targetUrl = this.getUserProfileURL();
    history.push(targetUrl);
  };

  onPortalSwitch = (targetRole) => {
    const { setUserProfile, history } = this.props;
    let targetUrl;
    switch (targetRole) {
      case USER_TYPES.SEED_ADVISOR:
        this.loadUserType = USER_TYPES.SEED_ADVISOR;
        targetUrl = SA_DASHBOARD;
        break;
      case USER_TYPES.SALES_REPRESENTATIVE:
        this.loadUserType = USER_TYPES.SALES_REPRESENTATIVE;
        targetUrl = SR_DASHBOARD;
        break;
      case USER_TYPES.ADMIN:
        this.loadUserType = USER_TYPES.ADMIN;
        targetUrl = ADMIN_BULLETIN;
        break;
      case USER_TYPES.GROWER:
        this.loadUserType = USER_TYPES.GROWER;
        targetUrl = DASHBOARD;
        break;
      case USER_TYPES.CUST_SUPPORT_ADMIN:
        this.loadUserType = USER_TYPES.CUST_SUPPORT_ADMIN;
        targetUrl = CSA_DASHBOARD;
        break;
      default:
        this.loadUserType = undefined;
    }
    setUserProfile(undefined);
    history.push(targetUrl);
  };

  onNotificationsClick = () => {
    const { history, user } = this.props;
    if (user.userType === USER_TYPES.GROWER) {
      history.push('/grower/notifications');
    } else if (user.userType === USER_TYPES.SEED_ADVISOR) {
      history.push('/seed-advisor/notifications');
    } else if (user.userType === USER_TYPES.CUST_SUPPORT_ADMIN) {
      history.push('/customer-support/notifications');
    } else if (user.userType === USER_TYPES.SALES_REPRESENTATIVE) {
      history.push('/sales-representative/notifications');
    }
  };

  checkAccessURL() {
    const { user } = this.props;
    if (user.userType === USER_TYPES.GROWER) {
      return 'grower';
    } else if (user.userType === USER_TYPES.SEED_ADVISOR) {
      return 'seed-advisor';
    } else if (user.userType === USER_TYPES.CUST_SUPPORT_ADMIN) {
      return 'customer-support';
    } else if (user.userType === USER_TYPES.SALES_REPRESENTATIVE) {
      return 'sales-representative';
    } else if (user.userType === USER_TYPES.ADMIN) {
      return 'administrator';
    }
  }

  toggleCollapsed = () =>
    this.setState({ isSiderCollapsed: !this.state.isSiderCollapsed });

  toggleHeaderMenu = () =>
    this.setState({ isHeaderMenuVisible: !this.state.isHeaderMenuVisible });

  getDualRoleMenuItem = () => {
    const { user, messages, commonMessages } = this.props;
    if (user && user.userType && user.roles && user.roles.length > 1) {
      const roleMsg = {
        [USER_TYPES.GROWER]: messages.menu.grower_portal,
        [USER_TYPES.SEED_ADVISOR]: messages.menu.seed_advisor_portal,
        [USER_TYPES.SALES_REPRESENTATIVE]:
          messages.menu.sales_representative_portal,
        [USER_TYPES.ADMIN]: messages.menu.admin_portal,
        [USER_TYPES.CUST_SUPPORT_ADMIN]: messages.menu.cas_portal,
      };
      return (
        <Menu.SubMenu
          key="switch-to-menu"
          icon={<TeamOutlined />}
          title={commonMessages.switch_to}
        >
          {user.roles
            .filter((role) => role !== user.userType)
            .map((role) => {
              return (
                <Menu.Item
                  key={`role-${role}`}
                  onClick={() => this.onPortalSwitch(role)}
                >
                  {roleMsg[role]}
                </Menu.Item>
              );
            })}
        </Menu.SubMenu>
      );
    }
  };

  profileNavLink = (userType) => {
    switch (userType) {
      case USER_TYPES.GROWER:
        return GET_GROWER_PROFILE();
      case USER_TYPES.SEED_ADVISOR:
        return SA_PROFILE;
      case USER_TYPES.CUST_SUPPORT_ADMIN:
        return CSA_PROFILE;
      default:
        return '';
    }
  };

  userMenu = () => {
    const { user, logoutUser, messages, sessionEmail: email } = this.props;
    const showEmail = !(trim(user.first_name) || trim(user.last_name));
    const initial = upperCase(
      showEmail
        ? `${email[0]}`
        : `${user.first_name[0]}${user.last_name ? user.last_name[0] : ''}`
    );

    return (
      <span>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="profile"
                icon={<UserOutlined />}
                onClick={this.navigateToProfile}
              >
                <NavLink to="">{messages.menu.profile}</NavLink>
              </Menu.Item>
              {this.getDualRoleMenuItem()}
              <Menu.Item
                key="sign-out"
                icon={<LogoutOutlined />}
                onClick={logoutUser}
              >
                {messages.menu.sign_out}
              </Menu.Item>
            </Menu>
          }
        >
          <Button className="user-avtar-cnt">
            <Row gutter={6}>
              <Col>
                <Avatar>{initial}</Avatar>
              </Col>
              <Col>
                <p className="user-name">
                  {showEmail
                    ? email
                    : startCase(
                        lowerCase(
                          `${user.first_name} ${
                            user.last_name ? user.last_name : ''
                          }`
                        )
                      )}
                </p>
                <p className="user-type">
                  {this.getUserTypeValue(user.userType)}
                </p>
              </Col>
            </Row>
          </Button>
        </Dropdown>
      </span>
    );
  };

  headerMenu = () => {
    const { location, menuItems } = this.props;
    return (
      <Dropdown
        className="header-menu"
        visible={this.state.isHeaderMenuVisible}
        overlay={
          <Menus
            menuItems={menuItems}
            currPath={get(location, 'pathname', '')}
            toggleMenu={this.toggleHeaderMenu}
          />
        }
        trigger={['click']}
      >
        <Avatar
          size={48}
          icon={<BarsOutlined />}
          onClick={this.toggleHeaderMenu}
        />
      </Dropdown>
    );
  };

  footerContent = () => {
    const { messages } = this.props;
    return (
      <Row>
        <Col span={15}>
          <Row gutter={16}>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/copyright.aspx"
              >
                {new Date().getFullYear()}©{' ' + messages.syngenta}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/useragreement.html"
              >
                {messages.user_agreement}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.online_privacy_policy}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/cookiepolicy.html"
              >
                {messages.cookie_policy}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/SMSpolicy.html"
              >
                {messages.sms_t_c}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.dont_sell}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.syngenta-us.com/legal/privacypolicy.html"
              >
                {messages.limit_sensitive_info}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
          </Row>
        </Col>
        <Col span={9}>
          <Row gutter={{ xs: 8, sm: 16 }}>
            <Col>{messages.ga_support}</Col>
            <Col>
              <a href="mailto: support@goldenadvantage.com">
                {messages.support_email}
              </a>
              <Divider className="footer-divider" type="vertical" />
            </Col>
            <Col>{messages.support_phone}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  getUserTypeValue = (userType) => {
    const { smallScreen } = this.state;
    if (smallScreen) {
      const personaShortNameMapping = {
        ADMINISTRATOR: 'ADMIN',
        CUSTOMER_SUPPORT: 'CAS',
        GROWER: 'GROWER',
        SALES_REPRESENTATIVE: 'SALES REP',
        SEED_ADVISOR: 'SA',
      };
      return personaShortNameMapping[userType];
    }
    return userType.replace('_', ' ');
  };

  render() {
    const { messages, user, location, menuItems, session, sessionEmail } =
      this.props;
    const { isSiderCollapsed } = this.state;
    const notificationCount =
      user &&
      user.notifications &&
      user.notifications.filter((x) => x.is_read_completed !== true);
    const { Header } = Layout;
    const { Footer } = Layout;
    const timeout = getUserIdleTimeoutDuration(sessionEmail);
    const isInActiveGrowerWithNoCreditHistory =
      checkInactiveGrowerWithNoCreditHistory(user);
    const showNtc = verifyNotice();
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (userDetails !== null) userDetails['showNoticeModal'] = showNtc;

    if (isInActiveGrowerWithNoCreditHistory)
      return (
        <Modal
          visible={isInActiveGrowerWithNoCreditHistory}
          transitionName=""
          onOk={() => {
            this.props.logoutUser();
          }}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Result
            status="warning"
            title={messages.inactive_grower_error_title}
            subTitle={
              <div>
                {messages.inactive_grower_error_subtitle}
                <a href="mailto:support@goldenadvantage.com">
                  support@goldenadvantage.com
                </a>
              </div>
            }
          />
        </Modal>
      );

    return (
      <React.Fragment>
        <Spin spinning={!(session && session.accessToken) || !user}>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={timeout}
            onAction={this.onAction}
            onIdle={this.onIdle}
            onActive={this.onActive}
            debounce={500}
          />

          <Layout className="main-container">
            <IdleTimeOutModal
              showModal={this.state.showModal}
              messages={messages}
              handleClose={this.handleClose}
              handleLogout={this.handleLogout}
            />

            <NoticeModal
              showModal={this.showNtc}
              policyClk={this.state.policyClk}
              agreeClk={this.state.agreeClk}
              messages={messages}
              handleClose={this.handleNoticeClose}
              handlepolicyClick={this.handlepolicyClick}
              handleagrrementClick={this.handleagrrementClick}
              handleLogout={this.handleLogout}
            />

            <Header className="app-header" mode="inline">
              {user && (
                <Row className="action-container">
                  <Col className="ant-col-xs-4">
                    <img src={logo} className="logo" alt="logo" />
                    {this.headerMenu()}
                  </Col>
                  <Col className="headerInfoAlignment  ant-col-xs-20">
                    <span>
                      {getEnvironmentValue(SALES_YEAR_CONFIG) && (
                        <span>
                          <FinancialYearSelector />
                        </span>
                      )}
                    </span>
                    <div
                      className="notif-container"
                      onClick={() => this.onNotificationsClick()}
                    >
                      <img
                        src={notification}
                        alt="Notification Icon"
                        className="mail"
                      />
                      <span
                        className={
                          notificationCount?.length > 0
                            ? 'message-notification badgecolor'
                            : ''
                        }
                        data-badge="2"
                      >
                        {notificationCount?.length > 0
                          ? notificationCount?.length
                          : ''}
                      </span>
                    </div>
                    {this.userMenu()}
                  </Col>
                </Row>
              )}
            </Header>

            <div
              className={classNames('toggle-collapse-ele-cnt', {
                'toggle-element-expanded-margin': !isSiderCollapsed,
                'toggle-element-collapsed-margin': isSiderCollapsed,
              })}
            >
              <Tooltip
                placement="right"
                title={isSiderCollapsed ? messages.expand : messages.collapse}
              >
                <Avatar
                  className="toggle-collapse-ele"
                  icon={isSiderCollapsed ? <RightOutlined /> : <LeftOutlined />}
                  onClick={this.toggleCollapsed}
                />
              </Tooltip>
            </div>
            <SideMenu
              className="side-menu"
              menu={messages.menu}
              currPath={get(location, 'pathname', '')}
              isSiderCollapsed={isSiderCollapsed}
              menuItems={menuItems}
            />

            <Layout className="content-layout">
              <ContentBody isSiderCollapsed={isSiderCollapsed} user={user} />
              <Footer className="footer">{this.footerContent()}</Footer>
            </Layout>
          </Layout>
        </Spin>
        <CookieConsent />
        <CookieInfo
          showModal={this.state.showCookie}
          closeCookieDetail={this.closeCookiePolicy}
          messages={messages}
        />
      </React.Fragment>
    );
  }
}
function checkInactiveGrowerWithNoCreditHistory(user) {
  if (user) {
    const isGrowerUserType =
      !!user.userType && user.userType.toLowerCase() === 'grower';
    const isInactiveGrower = isGrowerUserType && isGrowerInactive(user);
    return !user.grower_credit_histories?.length && isInactiveGrower;
  }
  return false;
}

function verifyNotice() {
  try {
    let userDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    if (
      userDetails?.is_agreements_agreed !== undefined &&
      !userDetails?.is_agreements_agreed
    ) {
      return true;
    }
  } catch (e) {
    console.log('error');
  }
  return false;
}
export default Landing;
