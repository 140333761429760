import { trim } from 'lodash';

export const isSeedAdvProfileComplete = (user) => {
  return !!(
    user &&
    trim(user.first_name).length &&
    trim(user.last_name).length &&
    trim(user.phone_number_mobile).length
  );
};
