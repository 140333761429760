export function isGaUnderMaintenance(settingsResponse) {
  const maintenanceStartTimeInResponse = settingsResponse.maintenanceStartTime;
  const maintenanceEndTimeInResponse = settingsResponse.maintenanceEndTime;
  if (!maintenanceStartTimeInResponse && !maintenanceEndTimeInResponse)
    return false;

  const currentTime = new Date(new Date(Date.now()).toUTCString());
  const maintenanceStartTime = new Date(
    maintenanceStartTimeInResponse
  ).toUTCString();
  if (!maintenanceEndTimeInResponse)
    return new Date(maintenanceStartTime) <= currentTime;

  const maintenanceEndTime = new Date(
    maintenanceEndTimeInResponse
  ).toUTCString();
  if (!maintenanceStartTimeInResponse)
    return currentTime <= new Date(maintenanceEndTime);

  return (
    new Date(maintenanceStartTime) <= currentTime &&
    currentTime <= new Date(maintenanceEndTime)
  );
}
