import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Modal } from 'antd';
import { getUserTypeLogoutUrl } from '../utilities/UtilityFunctions';
import { toLower, get } from 'lodash';
import { ApiHOC, MessageHOC } from '../shared';
import messageIds from './messages';
import { SET_OKTA_ERROR } from '../constants/ApiConstants';

function isClockDriftError(error) {
  const clockDriftOktaErrors = [
    'The JWT expired',
    'The JWT was issued in the future',
  ];

  return Boolean(
    clockDriftOktaErrors.find((description) =>
      error.message.includes(description)
    )
  );
}

function getErrorMessage(messages, error) {
  const knownErrorNames = {
    oautherror: messages.verify_account_error,
  };

  if (isClockDriftError(error)) {
    return messages.clock_drift_error;
  }
  return (
    knownErrorNames[toLower(error.name)] || `${error.name}: ${error.message}`
  );
}

const getErrorContent = (error, messages, POSTCALL) => {
  if (error.name && error.message) {
    const msg = getErrorMessage(messages, error);
    POSTCALL(SET_OKTA_ERROR, { error });
    return msg;
  }
  POSTCALL(SET_OKTA_ERROR, { error });
  return `Error: ${error.toString()}`;
};

class OktaError extends Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.formattedMsgs = this.props.getMessages(messageIds);
  }

  async onOk() {
    try {
      this.props.oktaAuth &&
        (await this.props.oktaAuth.signOut({
          postLogoutRedirectUri:
            window.location.origin + getUserTypeLogoutUrl(),
        }));
    } finally {
      Modal.destroyAll();
    }
  }

  componentDidMount() {
    Modal.destroyAll();
    Modal.error({
      title: 'Error Occurred !!',
      centered: true,
      content: (
        <div>
          <p>
            {getErrorContent(
              this.props.error,
              this.formattedMsgs,
              this.props.POST
            )}
          </p>
          <p>{get(this, 'formattedMsgs.verify_account_error_help')}</p>
        </div>
      ),
      onOk: this.onOk,
    });
  }

  render() {
    return null;
  }
}

export default ApiHOC()(MessageHOC()(withOktaAuth(OktaError)));
