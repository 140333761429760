import React from 'react';
import { Form, Checkbox } from 'antd';
import { isRequired } from '../../utilities/UtilityFunctions';

const CheckboxComponent = ({ input, label, disabled, meta, rules }) => {
  let customClass = meta.error ? 'ant-form-item-has-error' : '';

  if (isRequired(rules)) {
    customClass = `${customClass} item-required`;
  }
  if (meta.inline) {
    customClass = `${customClass} ant-form-item-inline`;
  }

  if (meta.noDashes) {
    customClass = `${customClass} ant-form-item-inline-no-dash `;
  }

  return (
    <Form.Item label={label} className={customClass}>
      <Checkbox
        label={label}
        checked={input.value ? true : false}
        onClick={(e) => input.onChange(e.target.checked)}
        value={input.value || ''}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export { CheckboxComponent };
