/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { LOGOUT_ACTION } from '../components/constants/ApplicationConstants';
import sessionReducer from '../components/security/reducer';
import history from './history';
import languageProviderReducer from '../components/language-provider/reducer';
import loginReducer from '../components/login/reducer';
import districtReducer from '../components/customer-support/store/reducer';
import salesRepPersonaReducer from '../components/sales-representative/store/reducer';
import salesYearReducer from '../components/shared/components/financialYearSelector/store/reducer';
import { commonReducer } from '../components/shared';

/**
 * Merges the main reducer with the router state
 * Please maintain the alphabetical order
 */

const appReducers = combineReducers({
  common: commonReducer,
  district: districtReducer,
  form: formReducer,
  language: languageProviderReducer,
  session: sessionReducer,
  router: connectRouter(history),
  user: loginReducer,
  salesRep: salesRepPersonaReducer,
  salesYear: salesYearReducer,
});

const rootReducer = (state, action) => {
  if (LOGOUT_ACTION === action.type) {
    state = undefined;
  }
  if ('AGGREE' === action.type) {
    state.isAgreementDone = true;
    // console.log('in in root reducer Agree executed');
  }
  return appReducers(state, action);
};
export default rootReducer;
