import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { initialState } from './reducer';
import { makeSelectLocale } from '../../language-provider/selectors';

/**
 * Direct selector to the common state domain
 */
const selectCommon = (state) => state.get('common', initialState);

const selectDropdown = createSelector(
  [selectCommon, makeSelectLocale],
  (commonState, locale) => commonState.get('dropdown').get(locale, Map())
);

const selectDropdownValue = createSelector(
  selectDropdown,
  (drops) => (dropKey) => drops.get(dropKey, [])
);

export { selectDropdown, selectDropdownValue };
