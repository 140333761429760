import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApiHOC, MessageHOC } from '../shared';
import App from './App';
import messageIds from './messages';
import { GET_SETTINGS } from '../constants/ApiConstants';
//import { parseJSON } from '../utilities/CommonApiUtils';
const mapStateToProps = (state, { getMessages, commonMessages }) => {
  const formattedMsg = getMessages(messageIds);
  return {
    messages: {
      ...formattedMsg,
      application_title: commonMessages.application_title,
      support_email: commonMessages.support_email,
      support_phone: commonMessages.support_phone,
    },
  };
};

const mapDispatchToProps = (_dispatch, ownProps) => ({
  getSettings: async (callback) => {
    return ownProps.GET(
      GET_SETTINGS,
      async (response) => {
        callback(response);
      },
      undefined,
      undefined,
      { skipAccessToken: true }
    );
  },
});

export default ApiHOC()(
  MessageHOC()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)))
);
