import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { message } from 'antd';
import {
  LOGOUT_ACTION,
  SET_SESSION_ACTION,
} from '../constants/ApplicationConstants';
import { notification } from 'antd';
import {
  GET_USER_PROFILE,
  PUT_INDV_SEED_ADVISORS,
  INDV_GROWER,
} from '../constants/ApiConstants';
import { ApiHOC, MessageHOC } from '../shared';
import { parseJSON } from '../utilities/CommonApiUtils';
import messageIds from './messages';

import Landing from './Landing';
import { setUserDetail } from '../login/action';
import { makeSelectUser, getUserMenus } from '../login/selectors';
import {
  getCurrentCropYear,
  getUserTypeLogoutUrl,
} from '../utilities/UtilityFunctions';
import cookieMessageIds from '../common/CookieConsent/messages';
import { getSession, getSessionEmail } from '../security/session-selector';
import { setSalesYear } from '../shared/components/financialYearSelector/store/action';

const mapStateToProps = (state, { getMessages, commonMessages }) => {
  const formattedMsg = getMessages(messageIds);
  const formattedCookieMsg = getMessages(cookieMessageIds);
  return {
    messages: {
      ...formattedMsg,
      ...formattedCookieMsg,
      support_email: commonMessages.support_email,
      support_phone: commonMessages.support_phone,
    },
    user: makeSelectUser(state),
    menuItems: getUserMenus(state),
    session: getSession(state),
    sessionEmail: getSessionEmail(state),
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const logoutUser = async () => {
    const { oktaAuth } = ownProps;
    //clear the states
    dispatch({ type: LOGOUT_ACTION });
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + getUserTypeLogoutUrl(),
    });
  };

  const aggreeClick = async () => {
    //clear the states
    dispatch({ type: 'AGGREE' });
  };

  return {
    ...stateProps,
    ...ownProps,
    setUserProfile: (data) => dispatch(setUserDetail(data)),
    updateNotificationStatus: (userDtl) => {
      const { PUT } = ownProps;
      // notification["id"] = "34343455";
      return PUT(
        userDtl.userType === 'SEED_ADVISOR'
          ? PUT_INDV_SEED_ADVISORS(
              userDtl.seed_advisor_id !== undefined &&
                userDtl.seed_advisor_id !== null &&
                userDtl.seed_advisor_id !== ''
                ? userDtl.seed_advisor_id
                : 0
            )
          : INDV_GROWER(
              userDtl.grower_id !== undefined &&
                userDtl.grower_id !== null &&
                userDtl.grower_id !== ''
                ? userDtl.grower_id
                : 0
            ),
        {
          isAgreementComplete: userDtl.is_agreements_agreed,
          sales_force_id: userDtl.sales_force_id,
        },
        async (resp) => {
          try {
            const jsonResp = await parseJSON(resp);
            if (jsonResp.success || jsonResp.ok) {
              notification.success({
                placement: 'topRight',
                message: 'Agreements are read!',
              });
              ownProps.setSuccessMsg(true);
            }
          } catch (e) {
            console.log(`Error in parse ${e}`);
          }
        },
        () => {
          notification.error({
            placement: 'topRight',
            message: 'Error reading agreement notice',
          });
          ownProps.setErrorMsg(true);
        }
      );
    },
    loadUserDetail: (email, currUserType, seedAdvisorID, zdPartnershipUuid) => {
      const { GET, commonMessages } = ownProps;
      sessionStorage.setItem('userEmail', email); //This is needed for payment when coming back from JPM because the stateProps will be undefined during that time
      return GET(
        GET_USER_PROFILE(email, currUserType, seedAdvisorID, zdPartnershipUuid),
        (resp) => {
          window.localStorage.removeItem('seed-advisor-id');
          window.localStorage.removeItem('zd-partnership-uuid');
          // console.log('JSON resp user profile');
          // console.log(JSON.stringify(resp));

          sessionStorage.setItem('userInfo', JSON.stringify(resp)); //This is needed for payment when coming back from JPM because the stateProps will be undefined during that time
          dispatch(setSalesYear(getCurrentCropYear()));
          dispatch(setUserDetail(resp));
        },
        (errResp) => {
          window.localStorage.removeItem('seed-advisor-id');
          window.localStorage.removeItem('zd-partnership-uuid');
          message.config({
            maxCount: 1,
          });
          message.error(commonMessages.server_error);
          setTimeout(() => {
            logoutUser();
          }, 3000);
        }
      );
    },
    logoutUser: logoutUser,
    aggreeClick: aggreeClick,
    setSession: (session) => {
      dispatch({
        type: SET_SESSION_ACTION,
        payload: { session },
      });
    },
  };
};

export default ApiHOC()(
  MessageHOC()(
    withRouter(
      withOktaAuth(connect(mapStateToProps, null, mergeProps)(Landing))
    )
  )
);
