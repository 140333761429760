const SET_LOGGED_IN_USER_DET = 'app/login/SET_LOGGED_IN_USER_DET';
const SET_VIEW_USER_DET = 'app/login/SET_VIEW_USER_DET';

const setUserDetail = (userDet) => ({
  type: SET_LOGGED_IN_USER_DET,
  userDet: userDet,
});

const setViewUserDetail = (userDet) => ({
  type: SET_VIEW_USER_DET,
  userDet: userDet,
});

export {
  SET_LOGGED_IN_USER_DET,
  SET_VIEW_USER_DET,
  setUserDetail,
  setViewUserDetail,
};
