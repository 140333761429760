import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import './landing.less';

export default function IdleTimeOutModal(props) {
  const { showModal, messages, handleClose, handleLogout } = props;
  return (
    <Modal
      centered
      visible={showModal}
      closable={false}
      title={messages.idle_session}
      footer={null}
      maskClosable={false}
      className="idle-modal"
    >
      <>
        <Row gutter={[16, 16]}>
          <Col>
            <WarningOutlined className="idle-warning" />
          </Col>

          <Col className="idle-modal-body">{messages.still_active}</Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col>
            <Button type="default" key="logout" onClick={handleLogout}>
              {messages.logout}
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              htmlType="submit"
              key="stay"
              onClick={handleClose}
            >
              {messages.stay}
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  );
}
