import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import {
  corpSeasonYearOptions,
  getCurrentCropYear,
  isUatOrTestOrLocalEnv,
} from '../../../utilities/UtilityFunctions';
import './financialYearSelector.less';

export const FinancialYearSelector = (props) => {
  const { salesYear, commonMessages, updateSalesYear } = props;
  const [getYearsDropDownList, setYearsDropDownList] = useState([]);
  const currentCropYear = getCurrentCropYear();

  const yearDropDownChange = (key) => {
    updateSalesYear(key);
    sessionStorage.setItem('CropYearSelected', key);
  };

  useEffect(() => {
    sessionStorage.setItem('CropYearSelected', currentCropYear);

    let yearOptions = corpSeasonYearOptions;

    if (isUatOrTestOrLocalEnv()) {
      yearOptions = [2025, ...yearOptions];
    }

    const yearsDropDown = yearOptions.map((year) => ({
      key: year,
      label: year,
    }));
    setYearsDropDownList(yearsDropDown);
  }, []);

  return (
    <Dropdown
      className="financial-year-selector"
      overlay={
        <Menu>
          {getYearsDropDownList.map((yearSelected) => {
            return (
              <Menu.Item
                onClick={() => yearDropDownChange(yearSelected.key)}
                key={yearSelected.key}
              >
                {yearSelected.label}
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <span>
        <span className={salesYear < currentCropYear ? 'warning-info' : ''}>
          {commonMessages.financial_year_selection_label}
        </span>
        <Button
          className={classnames(
            salesYear < currentCropYear ? 'warning-info' : '',
            'dropdown-menu-button'
          )}
        >
          {
            (
              getYearsDropDownList.find(
                (yearSelected) => yearSelected.key === salesYear
              ) || {}
            ).label
          }
        </Button>
      </span>
    </Dropdown>
  );
};
