import { fromJS } from 'immutable';
import ls from 'local-storage';

import { CHANGE_LOCALE } from './actions';
import { DEFAULT_LOCALE } from '../constants/ApplicationConstants';

export const getInitialState = () => {
  return fromJS({
    locale: ls.get('selected-locale') || DEFAULT_LOCALE,
  });
};

const languageProviderReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      //setting the locale selected to the local storage
      ls.set('selected-locale', action.locale);
      return state.set('locale', action.locale);
    default:
      return state;
  }
};

export default languageProviderReducer;
