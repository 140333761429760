import React, { Component } from 'react';
import { Select } from 'antd';
import { Map } from 'immutable';
import { find, get } from 'lodash';

export default class SelectDropdown extends Component {
  getMenu = () => {
    const { options, name, extraOptions } = this.props;
    const items = options || Map();
    const Option = Select.Option;
    let menus = [];
    if (extraOptions) {
      //adding default menu
      (extraOptions || []).forEach(({ key, value }) => {
        menus.push(
          <Option key={`${name}-${key}-def`} value={key}>
            {key}
          </Option>
        );
      });
    }
    if (Map.isMap(items)) {
      items.forEach((value, key) => {
        menus.push(
          <Option key={`${name}-${key}`} value={key}>
            {key}
          </Option>
        );
      });
    } else {
      items.forEach(({ key, value }, ind) => {
        menus.push(
          <Option key={`${name}-${key}-${ind}`} value={key}>
            {key}
          </Option>
        );
      });
    }
    return menus;
  };

  getLabel = (value) => {
    const { options } = this.props;
    const items = options || Map();
    if (Map.isMap(items)) {
      return items.get(value);
    }
    return get(
      find(items, (it) => it.key === value),
      'value'
    );
  };

  render() {
    const { name, value, disabled, onChange, noDataText, isSearchable } =
      this.props;
    let extraConfig = {};
    if (isSearchable) {
      extraConfig = {
        showSearch: true,
        filterOption: (input, option) => {
          return option.children.toLowerCase().startsWith(input.toLowerCase());
        },
      };
    }
    return (
      <div>
        <Select
          value={value}
          onChange={(val) => onChange(name, val, this.getLabel(val))}
          disabled={disabled}
          notFoundContent={
            noDataText ? <center>{noDataText}</center> : undefined
          }
          {...extraConfig}
        >
          {this.getMenu()}
        </Select>
      </div>
    );
  }
}
