import React from 'react';
import { Form, Input } from 'antd';
import Icon from '@ant-design/icons';
import { isRequired } from '../../utilities/UtilityFunctions';
import './TextView.less';

const TextView = ({
  input,
  type,
  placeholder,
  label,
  disabled,
  icon,
  meta,
  rules,
  ownValue,
  readOnly,
  customizedClass,
  prefix,
}) => {
  let customClass = meta.error ? 'ant-form-item-has-error' : '';
  if (isRequired(rules)) {
    customClass = `${customClass} item-required`;
  }
  return (
    <Form.Item labelCol={{ span: 24 }} label={label} className={customClass}>
      {!readOnly ? (
        <Input
          type={type || 'input'}
          prefix={(icon && <Icon type={icon} className="prefix" />) || prefix}
          onChange={input.onChange}
          value={ownValue || input.value}
          disabled={disabled}
          placeholder={placeholder || ''}
          className={customizedClass}
        />
      ) : (
        <div style={{ height: '32px' }}>
          <b style={{ fontSize: '20px' }}>{ownValue || input.value}</b>
        </div>
      )}
      {meta.error && <div className="ant-form-item-explain">{meta.error}</div>}
    </Form.Item>
  );
};

export { TextView };
