import React, { Component } from 'react';

import { hoistStatics } from './HOCCommon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { commonMessagesKeys } from '../common-messages';

export const MessageHOC = () => (WrappedComponent) => {
  const WrappedComponentDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  class WithMessageHOCComponent extends Component {
    static displayName = `WithMessageHOC(${WrappedComponentDisplayName})`;

    formatMessages = (messageObject) => {
      let messages = {};
      Object.entries(messageObject).forEach(([key, value]) => {
        if (typeof value == 'object') {
          messages[key] = this.formatMessages(value);
        } else {
          messages[key] = this.formatMessageWithParameters(value);
        }
      });
      return messages;
    };

    formatMessageWithParameters = (message, paramsMap = {}) => {
      const { intl } = this.props;
      return intl.formatMessage({ id: message }, paramsMap);
    };

    formatMessage = (message) => {
      return this.formatMessageWithParameters(message);
    };

    render() {
      return (
        <WrappedComponent
          getMessage={this.formatMessage}
          getMessageWithParam={this.formatMessageWithParameters}
          getMessages={this.formatMessages}
          commonMessages={this.formatMessages(commonMessagesKeys)}
          {...this.props}
        />
      );
    }
  }
  WithMessageHOCComponent = injectIntl(
    connect((state, { intl }) => ({
      intl,
    }))(WithMessageHOCComponent)
  );
  return hoistStatics(WithMessageHOCComponent, WrappedComponent);
};
