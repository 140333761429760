export const OKTA_IMPLICIT_CALLBACK = '/implicit/callback';
export const APP_HOME = '/';
export const VERIFY_ACCOUNT = '/verify-users';
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/cookie-policy';

const GROWER_BASE_URL = '/grower';
export const APP_GROWER_LOGIN = `${GROWER_BASE_URL}/login`;
export const CREATE_GROWER_ACCOUNT = `${GROWER_BASE_URL}/create-account`;
export const RESET_GROWER_PASSWORD = `${GROWER_BASE_URL}/reset-password`;
export const DASHBOARD = `${GROWER_BASE_URL}/dashboard`;
export const STATEMENTS = `${GROWER_BASE_URL}/statements`;
export const MAKE_PAYMENT = `${GROWER_BASE_URL}/payment`;
export const APPLY_FOR_CREDIT = `${GROWER_BASE_URL}/apply-for-credit`;
export const NOTIFICATIONS = `${GROWER_BASE_URL}/notifications`;
export const RESOURCES = `${GROWER_BASE_URL}/resources`;
export const PROFILE = `${GROWER_BASE_URL}/profile`;
export const GROWER_FORGOT_PASSWORD = `${GROWER_BASE_URL}/forgot-password`;
export const SHOW_PDF = `${GROWER_BASE_URL}/display-pdf`;
export const GET_GROWER_PROFILE = (tab) =>
  `${PROFILE}?type=${tab ? tab : 'overview'}`;

const SA_BASE_URL = '/seed-advisor';
export const APP_SEED_ADVISOR_LOGIN = `${SA_BASE_URL}/login`;
export const RESET_SEED_ADVISOR_PASSWORD = `${SA_BASE_URL}/reset-password`;
export const SA_DASHBOARD = `${SA_BASE_URL}/dashboard`;
export const SA_GROWERS = `${SA_BASE_URL}/growers`;
export const SA_IND_GROWER_PROFILE = (sid, id) =>
  `${SA_BASE_URL}/growers?pid=${sid}&id=${id}`;

export const SA_RESOURCES = `${SA_BASE_URL}/resources`;
export const SA_PROFILE = `${SA_BASE_URL}/profile`;
export const SA_FORGOT_PASSWORD = `${SA_BASE_URL}/forgot-password`;
export const SA_NOTIFICATIONS = `${SA_BASE_URL}/notifications`;
export const SA_COLLECTIONS = `${SA_BASE_URL}/collections`;
export const SA_SETUP_ACCOUNT = `${SA_BASE_URL}/setup-account`;

const SR_BASE_URL = '/sales-representative';
export const APP_SALES_REPRESENTATIVE_LOGIN = `${SR_BASE_URL}/login`;
export const SR_GROWERS = `${SR_BASE_URL}/growers`;
export const SR_SEED_ADVISOR = `${SR_BASE_URL}/seed-advisors`;
export const SR_RESOURCES = `${SR_BASE_URL}/resources`;
export const SR_DASHBOARD = `${SR_BASE_URL}/dashboard`;
export const SR_IND_SA_PROFILE = (sid, id) =>
  `${SR_BASE_URL}/seed-advisors?sid=${sid}&id=${id}`;
export const SR_IND_GROWER_PROFILE = (sid, id) =>
  `${SR_BASE_URL}/growers?sid=${sid}&id=${id}`;
export const SR_PROFILE = `${SR_BASE_URL}/profile`;
export const SR_NOTIFICATIONS = `${SR_BASE_URL}/notifications`;
export const SR_COLLECTIONS = `${SR_BASE_URL}/collections`;

const ADMIN_BASE_URL = '/administrator';
export const APP_ADMIN_LOGIN = `${ADMIN_BASE_URL}/login`;
export const ADMIN_BULLETIN = `${ADMIN_BASE_URL}/bulletin`;
export const ADMIN_RESOURCES = `${ADMIN_BASE_URL}/resources`;
export const ADMIN_RESOURCES_EDIT = `${ADMIN_RESOURCES}/edit`;
export const ADMIN_GROWER_NOTIFICATIONS = `${ADMIN_BASE_URL}/notifications`;
export const ADMIN_SETTINGS = `${ADMIN_BASE_URL}/settings`;
export const ADMIN_STATEMENTS = `${ADMIN_BASE_URL}/statements`;
export const ADMIN_BATCH_JOBS = `${ADMIN_BASE_URL}/batch-jobs`;
export const ADMIN_BATCH_JOB_EDIT = `${ADMIN_BATCH_JOBS}/edit`;
export const ADMIN_STATEMENT_MARKETING_ADS = `${ADMIN_BASE_URL}/statement-marketing-ads`;
export const ADMIN_STATEMENT_MARKETING_ADS_EDIT = `${ADMIN_STATEMENT_MARKETING_ADS}/edit`;
export const ADMIN_PAYMENT_SYNC = `${ADMIN_BASE_URL}/payment-sync`;

const CSA_BASE_URL = '/customer-support';
export const CSA_LOGIN = `${CSA_BASE_URL}/login`;
export const CSA_PROFILE = `${CSA_BASE_URL}/profile`;
export const CSA_ALL_SA = `${CSA_BASE_URL}/seed-advisors`;
export const CSA_DASHBOARD = `${CSA_BASE_URL}/dashboard`;
export const CSA_RESOURCES = `${CSA_BASE_URL}/resources`;
export const CSA_ALL_GROWER = `${CSA_BASE_URL}/growers`;
export const CSA_SEARCH = `${CSA_BASE_URL}/search`;
export const CSA_COLLECTIONS = `${CSA_BASE_URL}/collections`;
export const CAS_IND_GROWER_PROFILE = (pid, id) =>
  `${CSA_BASE_URL}/growers?pid=${pid}&id=${id}`;
export const CAS_IND_SA_PROFILE = (pid, id) =>
  `${CSA_BASE_URL}/seed-advisors?pid=${pid}&id=${id}`;

export const CSA_NOTIFICATIONS = `${CSA_BASE_URL}/notifications`;
