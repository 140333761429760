const GET = 'get';
const POST = 'post';
const PATCH = 'patch';
const PUT = 'put';
const DELETE = 'delete';

const parseJSON = async (response) => {
  let contentType =
    response && response.headers && response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return await response.json();
  }
  return Promise.resolve(response);
};

const promiseResolver = (promise) => {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => {
      return [err];
    });
};

const formHeader = (reqHeader, extraFlag) => {
  if (extraFlag.escapeHeader) {
    return {};
  }
  let header = extraFlag.useDefaultHeaders
    ? {}
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(reqHeader || {}),
      };
  if (extraFlag.access_token) {
    header = {
      ...header,
      'ga-token': `Bearer ${extraFlag.access_token}`,
    };
  }
  return {
    headers: header,
  };
};

const callApi = (requestMethod, endPoint, headerToSend, extraFlag, data) => {
  switch (requestMethod) {
    case GET:
      return fetch(endPoint, {
        method: requestMethod,
        ...headerToSend,
      });
    case POST:
    case PATCH:
    case PUT:
    case DELETE:
      let dataTosend;
      if (extraFlag && extraFlag.escapeStringify) {
        dataTosend = data;
      } else {
        dataTosend = JSON.stringify(data);
      }
      return fetch(endPoint, {
        method: requestMethod,
        body: dataTosend,
        ...headerToSend,
      });
    default:
  }
};

//making actual fetch call
const makeCall = async (
  requestMethod,
  endPoint,
  data = {},
  header = {},
  extraFlag = {}
) => {
  const headerToSend = formHeader(header, extraFlag);
  return await promiseResolver(
    callApi(requestMethod, endPoint, headerToSend, extraFlag, data)
  );
};

export { GET, POST, PATCH, PUT, DELETE, parseJSON, makeCall, promiseResolver };
