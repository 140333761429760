export const scope = 'src.components.Landing';

export default {
  menu: {
    seed_advisor_portal: `${scope}.menu.seed_advisor_portal`,
    sales_representative_portal: `${scope}.menu.sales_representative_portal`,
    grower_portal: `${scope}.menu.grower_portal`,
    admin_portal: `${scope}.menu.admin_portal`,
    cas_portal: `${scope}.menu.cas_portal`,
    profile: `${scope}.menu.profile`,
    sign_out: `${scope}.menu.sign_out`,
  },
  expand: `${scope}.expand`,
  collapse: `${scope}.collapse`,
  syngenta: `${scope}.footer.syngenta`,
  user_agreement: `${scope}.footer.user_agreement`,
  online_privacy_policy: `${scope}.footer.online_privacy_policy`,
  cookie_policy: `${scope}.footer.cookie_policy`,
  sms_t_c: `${scope}.footer.sms_t_c`,
  dont_sell: `${scope}.footer.dont_sell`,
  limit_sensitive_info: `${scope}.footer.limit_sensitive_info`,
  ga_support: `${scope}.footer.ga_support`,
  idle_session: `${scope}.idle_session`,
  still_active: `${scope}.still_active`,
  logout: `${scope}.logout`,
  stay: `${scope}.stay`,
  inactive_grower_error_title: `${scope}.inactive_grower_error_title`,
  inactive_grower_error_subtitle: `${scope}.inactive_grower_error_subtitle`,
};
