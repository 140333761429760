import React from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const TextSearch = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset,
}) => (
  <div style={{ padding: 8 }}>
    <Input
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="primary"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon={<SearchOutlined />}
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button
      onClick={() => handleReset(clearFilters)}
      size="small"
      style={{ width: 90 }}
    >
      Reset
    </Button>
  </div>
);

export default TextSearch;
