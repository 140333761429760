import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { get } from 'lodash';

const sessionState = (state) => state.get('session', initialState);
const getSession = createSelector(sessionState, (session) => {
  return session.get('session');
});
const getSessionEmail = (state) => get(getSession(state), 'email');

export { getSessionEmail, getSession };
