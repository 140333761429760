import { fromJS } from 'immutable';
import { SET_LOGGED_IN_USER_DET, SET_VIEW_USER_DET } from './action';

export const initialState = fromJS({
  user: undefined,
  viewUser: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN_USER_DET:
      let nextUser = action.userDet;
      const currUser = state.get('user');
      if (currUser && nextUser) {
        nextUser = {
          ...nextUser,
          userType: currUser.userType,
          roles: currUser.roles,
        };
      }
      return state.set('user', nextUser);
    case SET_VIEW_USER_DET:
      return state.set('viewUser', action.userDet);
    default:
      return state;
  }
};
