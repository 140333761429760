import React, { Component } from 'react';

import { hoistStatics } from './HOCCommon';
import {
  makeCall,
  GET,
  POST,
  parseJSON,
  PUT,
  DELETE,
  PATCH,
} from '../../utilities/CommonApiUtils';
import { withOktaAuth } from '@okta/okta-react';
import { notification } from 'antd';

export const ApiHOC = () => (WrappedComponent) => {
  const WrappedComponentDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  class WithApiHOCComponent extends Component {
    static displayName = `WithApi(${WrappedComponentDisplayName})`;
    state = {
      errorMsg: '',
      statusCode: '',
      successMsg: '',
      isLoading: false,
      loadingApiKeys: {},
    };

    render() {
      const { isLoading, errorMsg, successMsg, statusCode, loadingApiKeys } =
        this.state;
      return (
        <WrappedComponent
          {...this.props}
          isLoading={isLoading}
          errorMsg={errorMsg}
          statusCode={statusCode}
          clearMsgs={this.clearMsgs}
          successMsg={successMsg}
          setErrorMsg={this.setErrorMsg}
          setSuccessMsg={this.setSuccessMsg}
          currLoadingApis={loadingApiKeys}
          GET={this.get}
          POST={this.post}
          PUT={this.put}
          PATCH={this.patch}
          DEL={this.deleteCall}
        />
      );
    }

    getAccessToken = () => {
      return this.props.oktaAuth.getAccessToken();
    };

    setErrorMsg = (msg, code = '', autoClose = false) => {
      this.setState({ errorMsg: msg, statusCode: code });
      //clear out msg after 3 second
      if (autoClose) {
        setTimeout(() => this.setErrorMsg(''), 6000);
      }
    };

    clearMsgs = () => {
      this.setState({
        errorMsg: '',
        successMsg: '',
      });
    };

    setSuccessMsg = (msg, autoClose = false) => {
      this.setState({ successMsg: msg });
      //clear out msg after 3 second
      if (autoClose) {
        setTimeout(() => this.setSuccessMsg(''), 3000);
      }
    };

    updateState = async (apiKey, isLoading) => {
      const { loadingApiKeys } = this.state;
      let newLoadingApiKeys = {
        ...(loadingApiKeys || {}),
      };
      if (isLoading) {
        newLoadingApiKeys[apiKey] = isLoading;
      } else {
        delete newLoadingApiKeys[apiKey];
      }
      let newIsloading = !Object.keys(newLoadingApiKeys).every(
        (key) => !newLoadingApiKeys[key]
      );
      await this.updateStateAsync({
        isLoading: newIsloading,
        loadingApiKeys: newLoadingApiKeys,
      });
    };

    updateStateAsync = (newState) => {
      return new Promise((resolve) => this.setState(newState, () => resolve()));
    };

    isAlreadyLoading = (key) => {
      const { loadingApiKeys } = this.state;
      return (loadingApiKeys || {})[key];
    };

    get = async (
      url,
      callBack,
      errorCallback,
      name,
      extraFlag = {},
      header,
      retry = false
    ) => {
      try {
        const { locale } = this.props;
        let newExtraFlag = {
          ...extraFlag,
          locale,
        };
        if (!newExtraFlag.skipAccessToken) {
          const accessToken =
            this.getAccessToken() ||
            JSON.parse(window.localStorage.getItem('okta-token-storage'))
              ?.accessToken?.accessToken;
          newExtraFlag['access_token'] = accessToken;
        }
        if (retry || newExtraFlag.loadAlways || !this.isAlreadyLoading(url)) {
          this.updateState(url, true);
          const [err, resp] = await makeCall(
            GET,
            url,
            undefined,
            header,
            newExtraFlag
          );
          if (err || !resp.ok) {
            await this.handleError(
              err,
              resp,
              () =>
                this.get(
                  url,
                  callBack,
                  errorCallback,
                  name,
                  newExtraFlag,
                  header,
                  true
                ),
              url,
              errorCallback,
              callBack(err, name)
            );
          } else {
            let contentType = resp.headers && resp.headers.get('content-type');
            if (
              contentType &&
              contentType.includes('application/octet-stream')
            ) {
              callBack(resp, name);
            } else {
              let data = await parseJSON(resp);
              callBack(data, name);
            }
            this.updateState(url, false);
          }
        }
      } catch (error) {
        this.updateState(url, false);
      }
    };

    makeTypeCall = async (
      type,
      url,
      data,
      callBack,
      errorCallback,
      extraFlag,
      header
    ) => {
      try {
        this.updateState(url, true);
        const { locale } = this.props;
        extraFlag = {
          ...(extraFlag || {}),
          locale,
        };
        if (!extraFlag.skipAccessToken) {
          const accessToken = this.getAccessToken();
          extraFlag['access_token'] = accessToken;
        }
        const [err, resp] = await makeCall(type, url, data, header, extraFlag);
        if (err || !resp.ok) {
          return this.handleError(
            err,
            resp,
            () =>
              this.makeTypeCall(
                type,
                url,
                data,
                callBack,
                errorCallback,
                extraFlag,
                header
              ),
            url,
            errorCallback,
            extraFlag
          );
        }
        callBack(resp);
        this.updateState(url, false);
      } catch (error) {
        this.updateState(url, false);
      }
    };

    post = (url, data, callBack, errorCallback, extraFlag, header) => {
      return this.makeTypeCall(
        POST,
        url,
        data,
        callBack,
        errorCallback,
        extraFlag,
        header
      );
    };

    put = (url, data, callBack, errorCallback, extraFlag, header) => {
      return this.makeTypeCall(
        PUT,
        url,
        data,
        callBack,
        errorCallback,
        extraFlag,
        header
      );
    };

    patch = (url, data, callBack, errorCallback, extraFlag, header) => {
      return this.makeTypeCall(
        PATCH,
        url,
        data,
        callBack,
        errorCallback,
        extraFlag,
        header
      );
    };

    deleteCall = async (
      url,
      data,
      callBack,
      errorCallback,
      extraFlag,
      header
    ) => {
      this.makeTypeCall(
        DELETE,
        url,
        data,
        callBack,
        errorCallback,
        extraFlag,
        header
      );
    };

    handleError = async (
      err,
      rawResp,
      retry,
      urlKey,
      errorCallback,
      extraFlag
    ) => {
      let errorMsg = '';
      let resp;
      if (rawResp) {
        if (rawResp.status === 500) {
          let errorOccured = 'Some of the details are not valid.';
          notification.error({
            placement: 'topRight',
            message: errorOccured,
            description:
              'Please contact the GA support team if the issue persists',
          });
        }
        if (rawResp.status === 401) {
          notification.error({
            placement: 'topRight',
            message: 'Invalid session',
            description: 'Please signin again !!',
          });
        }
        if (rawResp.status === 400 && extraFlag.showGenericErrorMessage) {
          notification.error({
            placement: 'topRight',
            message: 'Ooops!!',
            description:
              'An error occurred, please contact customer support at (855)939-0058 or support@goldenadvantage.com',
          });
        }
        resp = await parseJSON(rawResp);
        errorMsg = resp.message;
      } else {
        rawResp = {
          message: 'Unable to connect to server',
          status: 503,
        };
      }
      this.setErrorMsg(errorMsg, rawResp.status);
      this.updateState(urlKey, false);
      errorCallback &&
        errorCallback(
          { errorMsg: errorMsg, code: rawResp.status },
          rawResp,
          resp
        );
    };
  }
  WithApiHOCComponent = withOktaAuth(WithApiHOCComponent);
  return hoistStatics(WithApiHOCComponent, WrappedComponent);
};
