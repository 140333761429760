import React from 'react';
import { DatePicker, Button, Row, Col } from 'antd';

const DateSearch = (props) => {
  const {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    handleSearch,
    handleReset,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        value={selectedKeys[0]}
        onChange={(dates, dateStrings) => {
          setSelectedKeys(dates ? [dates] : []);
          handleSearch(selectedKeys, confirm);
        }}
        format={'MM-DD-YYYY'}
        style={{ marginBottom: 8 }}
      />
      <Row justify="center">
        <Col>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DateSearch;
