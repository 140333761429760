export const scope = 'src.components.CookieConsent';

export default {
  CookieConsent: {
    description: `${scope}.CookieConsent.description`,
    tellmemore: `${scope}.CookieConsent.tellmemore`,
    okcontinue: `${scope}.CookieConsent.okcontinue`,
  },
  CookieInfo: {
    senten1: `${scope}.CookieInfo.senten1`,
    senten2: `${scope}.CookieInfo.senten2`,
    senten3: `${scope}.CookieInfo.senten3`,
    senten4: `${scope}.CookieInfo.senten4`,
    senten5: `${scope}.CookieInfo.senten5`,
    senten6: `${scope}.CookieInfo.senten6`,
    senten7: `${scope}.CookieInfo.senten7`,
    senten8: `${scope}.CookieInfo.senten8`,
    senten9: `${scope}.CookieInfo.senten9`,
    senten10: `${scope}.CookieInfo.senten10`,
    senten11: `${scope}.CookieInfo.senten11`,
    senten12: `${scope}.CookieInfo.senten12`,
    senten13: `${scope}.CookieInfo.senten13`,
    senten14: `${scope}.CookieInfo.senten14`,
    senten15: `${scope}.CookieInfo.senten15`,
    senten16: `${scope}.CookieInfo.senten16`,
    senten17: `${scope}.CookieInfo.senten17`,
    senten18: `${scope}.CookieInfo.senten18`,
    senten19: `${scope}.CookieInfo.senten19`,
    senten20: `${scope}.CookieInfo.senten20`,
    senten21: `${scope}.CookieInfo.senten21`,
    senten22: `${scope}.CookieInfo.senten22`,
    senten23: `${scope}.CookieInfo.senten23`,
    senten24: `${scope}.CookieInfo.senten24`,
    senten25: `${scope}.CookieInfo.senten25`,
    senten26: `${scope}.CookieInfo.senten26`,
    senten27: `${scope}.CookieInfo.senten27`,
    senten28: `${scope}.CookieInfo.senten28`,
    senten29: `${scope}.CookieInfo.senten29`,
    name: `${scope}.CookieInfo.name`,
    provider: `${scope}.CookieInfo.provider`,
    purpose: `${scope}.CookieInfo.purpose`,
    expiry: `${scope}.CookieInfo.expiry`,
    type: `${scope}.CookieInfo.type`,
    _ga_purpose: `${scope}.CookieInfo._ga_purpose`,
    _gat_purpose: `${scope}.CookieInfo._gat_purpose`,
    _gid_purpose: `${scope}.CookieInfo._gid_purpose`,
    t_purpose: `${scope}.CookieInfo.t_purpose`,
    cookieConsent_purpose: `${scope}.CookieInfo.cookieConsent_purpose`,
    sid_purpose: `${scope}.CookieInfo.sid_purpose`,
    jsessionid_purpose: `${scope}.CookieInfo.jsessionid_purpose`,
    yconsent_purpose: `${scope}.CookieInfo.yconsent_purpose`,
    dt_purpose: `${scope}.CookieInfo.dt_purpose`,
    ysc_purpose: `${scope}.CookieInfo.ysc_purpose`,
    visitor_info1_live_purpose: `${scope}.CookieInfo.visitor_info1_live_purpose`,
    yt_remote_device_id_purpose: `${scope}.CookieInfo.yt_remote_device_id_purpose`,
    yt_remote_connected_devices_purpose: `${scope}.CookieInfo.yt_remote_connected_devices_purpose`,
    yt_remote_session_app_purpose: `${scope}.CookieInfo.yt_remote_session_app_purpose`,
    yt_remote_cast_installed_purpose: `${scope}.CookieInfo.yt_remote_cast_installed_purpose`,
    yt_remote_session_name_purpose: `${scope}.CookieInfo.yt_remote_session_name_purpose`,
    yt_remote_cast_available_purpose: `${scope}.CookieInfo.yt_remote_cast_available_purpose`,
    yt_remote_fast_check_period_purpose: `${scope}.CookieInfo.yt_remote_fast_check_period_purpose`,
  },
  PrivacyPolicy: {
    privacy_statement: `${scope}.PrivacyPolicy.privacy_statement`,
    introduction: `${scope}.PrivacyPolicy.introduction`,
    introduction_desc: `${scope}.PrivacyPolicy.introduction_desc`,
    effective_date: `${scope}.PrivacyPolicy.effective_date`,
    effective_date_desc: `${scope}.PrivacyPolicy.effective_date_desc`,
    scope_of_this_statement: `${scope}.PrivacyPolicy.scope_of_this_statement`,
    scope_of_this_statement_desc: `${scope}.PrivacyPolicy.scope_of_this_statement_desc`,
    your_consent: `${scope}.PrivacyPolicy.your_consent`,
    your_consent_desc: `${scope}.PrivacyPolicy.your_consent_desc`,
    personal_data_and_information: `${scope}.PrivacyPolicy.personal_data_and_information`,
    personal_data_and_information_desc1: `${scope}.PrivacyPolicy.personal_data_and_information_desc1`,
    personal_data_and_information_desc2: `${scope}.PrivacyPolicy.personal_data_and_information_desc2`,
    agreement: `${scope}.PrivacyPolicy.agreement`,
    agreement_desc: `${scope}.PrivacyPolicy.agreement_desc`,
    cookie_similar_technology: `${scope}.PrivacyPolicy.cookie_similar_technology`,
    cookie_similar_technology_desc1: `${scope}.PrivacyPolicy.cookie_similar_technology_desc1`,
    cookie_similar_technology_desc2: `${scope}.PrivacyPolicy.cookie_similar_technology_desc2`,
    cookie_similar_technology_desc3: `${scope}.PrivacyPolicy.cookie_similar_technology_desc3`,
    cookie_similar_technology_desc4: `${scope}.PrivacyPolicy.cookie_similar_technology_desc4`,
    cookie_similar_technology_desc5: `${scope}.PrivacyPolicy.cookie_similar_technology_desc5`,
    cookie_similar_technology_desc6: `${scope}.PrivacyPolicy.cookie_similar_technology_desc6`,
    cookie_similar_technology_desc7: `${scope}.PrivacyPolicy.cookie_similar_technology_desc7`,
    cookie_similar_technology_desc8: `${scope}.PrivacyPolicy.cookie_similar_technology_desc8`,
    use_personal_info: `${scope}.PrivacyPolicy.use_personal_info`,
    use_personal_info_desc1: `${scope}.PrivacyPolicy.use_personal_info_desc1`,
    use_personal_info_desc2: `${scope}.PrivacyPolicy.use_personal_info_desc2`,
    use_personal_info_desc3: `${scope}.PrivacyPolicy.use_personal_info_desc3`,
    use_personal_info_desc4: `${scope}.PrivacyPolicy.use_personal_info_desc4`,
    use_personal_info_desc5: `${scope}.PrivacyPolicy.use_personal_info_desc5`,
    use_personal_info_desc6: `${scope}.PrivacyPolicy.use_personal_info_desc6`,
    use_personal_info_desc7: `${scope}.PrivacyPolicy.use_personal_info_desc7`,
    use_personal_info_desc8: `${scope}.PrivacyPolicy.use_personal_info_desc8`,
    use_personal_info_desc9: `${scope}.PrivacyPolicy.use_personal_info_desc9`,
    use_personal_info_desc10: `${scope}.PrivacyPolicy.use_personal_info_desc10`,
    use_personal_info_desc11: `${scope}.PrivacyPolicy.use_personal_info_desc11`,
    use_personal_info_desc12: `${scope}.PrivacyPolicy.use_personal_info_desc12`,
    use_personal_info_desc13: `${scope}.PrivacyPolicy.use_personal_info_desc13`,
    use_personal_info_desc14: `${scope}.PrivacyPolicy.use_personal_info_desc14`,
    use_personal_info_desc15: `${scope}.PrivacyPolicy.use_personal_info_desc15`,
    share_personal_info: `${scope}.PrivacyPolicy.share_personal_info`,
    share_personal_info_desc1: `${scope}.PrivacyPolicy.share_personal_info_desc1`,
    share_personal_info_desc2: `${scope}.PrivacyPolicy.share_personal_info_desc2`,
    share_personal_info_desc3: `${scope}.PrivacyPolicy.share_personal_info_desc3`,
    share_personal_info_desc4: `${scope}.PrivacyPolicy.share_personal_info_desc4`,
    share_personal_info_desc5: `${scope}.PrivacyPolicy.share_personal_info_desc5`,
    share_personal_info_desc6: `${scope}.PrivacyPolicy.share_personal_info_desc6`,
    share_personal_info_desc7: `${scope}.PrivacyPolicy.share_personal_info_desc7`,
    your_choise: `${scope}.PrivacyPolicy.your_choise`,
    your_choises_desc1: `${scope}.PrivacyPolicy.your_choises_desc1`,
    your_choises_desc2: `${scope}.PrivacyPolicy.your_choises_desc2`,
    your_choises_desc3: `${scope}.PrivacyPolicy.your_choises_desc3`,
    exercise_your_rights: `${scope}.PrivacyPolicy.exercise_your_rights`,
    exercise_your_rights_desc1: `${scope}.PrivacyPolicy.exercise_your_rights_desc1`,
    exercise_your_rights_desc2: `${scope}.PrivacyPolicy.exercise_your_rights_desc2`,
    exercise_your_rights_desc3: `${scope}.PrivacyPolicy.exercise_your_rights_desc3`,
    exercise_your_rights_desc4: `${scope}.PrivacyPolicy.exercise_your_rights_desc4`,
    exercise_your_rights_desc5: `${scope}.PrivacyPolicy.exercise_your_rights_desc5`,
    exercise_your_rights_desc6: `${scope}.PrivacyPolicy.exercise_your_rights_desc6`,
    exercise_your_rights_desc7: `${scope}.PrivacyPolicy.exercise_your_rights_desc7`,
    exercise_your_rights_desc8: `${scope}.PrivacyPolicy.exercise_your_rights_desc8`,
    exercise_your_rights_desc9: `${scope}.PrivacyPolicy.exercise_your_rights_desc9`,
    exercise_your_rights_desc10: `${scope}.PrivacyPolicy.exercise_your_rights_desc10`,
    exercise_your_rights_desc11: `${scope}.PrivacyPolicy.exercise_your_rights_desc11`,
    how_long_store: `${scope}.PrivacyPolicy.how_long_store`,
    how_long_store_desc: `${scope}.PrivacyPolicy.how_long_store_desc`,
    protest_personal_info: `${scope}.PrivacyPolicy.protest_personal_info`,
    protest_personal_info_Desc: `${scope}.PrivacyPolicy.protest_personal_info_Desc`,
    link_to_other_syn_sites: `${scope}.PrivacyPolicy.link_to_other_syn_sites`,
    link_to_other_syn_sites_desc: `${scope}.PrivacyPolicy.link_to_other_syn_sites_desc`,
    link_to_other_sites: `${scope}.PrivacyPolicy.link_to_other_sites`,
    link_to_other_sites_desc: `${scope}.PrivacyPolicy.link_to_other_sites_desc`,
    children: `${scope}.PrivacyPolicy.children`,
    children_desc: `${scope}.PrivacyPolicy.children_desc`,
    spl_notice: `${scope}.PrivacyPolicy.spl_notice`,
    spl_notice_desc1: `${scope}.PrivacyPolicy.spl_notice_desc1`,
    spl_notice_desc2: `${scope}.PrivacyPolicy.spl_notice_desc2`,
    spl_notice_desc3: `${scope}.PrivacyPolicy.spl_notice_desc3`,
    govt_law_juri: `${scope}.PrivacyPolicy.govt_law_juri`,
    govt_law_juri_desc: `${scope}.PrivacyPolicy.govt_law_juri_desc`,
    privacy_practi: `${scope}.PrivacyPolicy.privacy_practi`,
    privacy_practi_desc1: `${scope}.PrivacyPolicy.privacy_practi_desc1`,
    privacy_practi_desc2: `${scope}.PrivacyPolicy.privacy_practi_desc2`,
    Requests: `${scope}.PrivacyPolicy.Requests`,
    Requests_desc1: `${scope}.PrivacyPolicy.Requests_desc1`,
    Requests_desc2: `${scope}.PrivacyPolicy.Requests_desc2`,
    Requests_desc3: `${scope}.PrivacyPolicy.Requests_desc3`,
    Requests_desc4: `${scope}.PrivacyPolicy.Requests_desc4`,
    Requests_desc5: `${scope}.PrivacyPolicy.Requests_desc5`,
    Requests_desc6: `${scope}.PrivacyPolicy.Requests_desc6`,
    contact: `${scope}.PrivacyPolicy.contact`,
    googleprivacy: `${scope}.PrivacyPolicy.googleprivacy`,
    addiInfoTitle: `${scope}.PrivacyPolicy.addiInfoTitle`,
    addiInfo1: `${scope}.PrivacyPolicy.addiInfo1`,
    addiInfo2: `${scope}.PrivacyPolicy.addiInfo2`,
    collectPersonalInfo: `${scope}.PrivacyPolicy.collectPersonalInfo`,
    collectPersonalInfoText: `${scope}.PrivacyPolicy.collectPersonalInfoText`,
    collectPersonalInfoTextTableCol1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableCol1`,
    collectPersonalInfoTextTableCol2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableCol2`,
    collectPersonalInfoTextTableCol3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableCol3`,
    collectPersonalInfoTextTableCol4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableCol4`,
    collectPersonalInfoTextTableCol5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableCol5`,
    collectPersonalInfoTextTableRow1Col1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow1Col1`,
    collectPersonalInfoTextTableRow1Col2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow1Col2`,
    collectPersonalInfoTextTableRow1Col3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow1Col3`,
    collectPersonalInfoTextTableRow1Col4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow1Col4`,
    collectPersonalInfoTextTableRow1Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow1Col5`,
    collectPersonalInfoTextTableRow2Col1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow2Col1`,
    collectPersonalInfoTextTableRow2Col2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow2Col2`,
    collectPersonalInfoTextTableRow2Col3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow2Col3`,
    collectPersonalInfoTextTableRow2Col4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow2Col4`,
    collectPersonalInfoTextTableRow2Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow2Col5`,
    collectPersonalInfoTextTableRow3Col1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow3Col1`,
    collectPersonalInfoTextTableRow3Col2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow3Col2`,
    collectPersonalInfoTextTableRow3Col3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow3Col3`,
    collectPersonalInfoTextTableRow3Col4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow3Col4`,
    collectPersonalInfoTextTableRow3Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow3Col5`,
    collectPersonalInfoTextTableRow4Col1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow4Col1`,
    collectPersonalInfoTextTableRow4Col2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow4Col2`,
    collectPersonalInfoTextTableRow4Col3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow4Col3`,
    collectPersonalInfoTextTableRow4Col4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow4Col4`,
    collectPersonalInfoTextTableRow4Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow4Col5`,
    collectPersonalInfoTextTableRow5Col1: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col1`,
    collectPersonalInfoTextTableRow5Col2: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col2`,
    collectPersonalInfoTextTableRow5Col3: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col3`,
    collectPersonalInfoTextTableRow5Col4: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col4`,
    collectPersonalInfoTextTableRow5Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col5`,
    collectPersonalInfoTextTableRow5Col5: `${scope}.PrivacyPolicy.collectPersonalInfoTextTableRow5Col5`,
    usPrivacyLaws1: `${scope}.PrivacyPolicy.usPrivacyLaws1`,
    usPrivacyLaws2: `${scope}.PrivacyPolicy.usPrivacyLaws2`,
    usPrivacyLaws3: `${scope}.PrivacyPolicy.usPrivacyLaws3`,
    usPrivacyLaws4: `${scope}.PrivacyPolicy.usPrivacyLaws4`,
    usPrivacyLaws5: `${scope}.PrivacyPolicy.usPrivacyLaws5`,
    usPrivacyLaws6: `${scope}.PrivacyPolicy.usPrivacyLaws6`,
    usPrivacyLaws7: `${scope}.PrivacyPolicy.usPrivacyLaws7`,
    usPrivacyLaws8: `${scope}.PrivacyPolicy.usPrivacyLaws8`,
    usPrivacyLaws9: `${scope}.PrivacyPolicy.usPrivacyLaws9`,
    usPrivacyLaws10: `${scope}.PrivacyPolicy.usPrivacyLaws10`,
    usPrivacyLaws11: `${scope}.PrivacyPolicy.usPrivacyLaws11`,
    usPrivacyLaws12: `${scope}.PrivacyPolicy.usPrivacyLaws12`,
    usPrivacyLaws13: `${scope}.PrivacyPolicy.usPrivacyLaws13`,
    verificationProcess: `${scope}.PrivacyPolicy.verificationProcess`,
    verificationProcessText: `${scope}.PrivacyPolicy.verificationProcessText`,
    nonDiscrimination: `${scope}.PrivacyPolicy.nonDiscrimination`,
    nonDiscriminationText: `${scope}.PrivacyPolicy.nonDiscriminationText`,
    authorizedAgents: `${scope}.PrivacyPolicy.authorizedAgents`,
    authorizedAgentsText1: `${scope}.PrivacyPolicy.authorizedAgentsText1`,
    authorizedAgentsText2: `${scope}.PrivacyPolicy.authorizedAgentsText2`,
    authorizedAgentsText3: `${scope}.PrivacyPolicy.authorizedAgentsText3`,
    authorizedAgentsText4: `${scope}.PrivacyPolicy.authorizedAgentsText4`,
    authorizedAgentsText5: `${scope}.PrivacyPolicy.authorizedAgentsText5`,
    authorizedAgentsText6: `${scope}.PrivacyPolicy.authorizedAgentsText6`,
    personalInformationOfMirrors: `${scope}.PrivacyPolicy.personalInformationOfMirrors`,
    personalInformationOfMirrorsText: `${scope}.PrivacyPolicy.personalInformationOfMirrorsText`,
    Changes: `${scope}.PrivacyPolicy.Changes`,
    ChangesText: `${scope}.PrivacyPolicy.ChangesText`,
  },
};
