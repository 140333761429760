import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from '../security/OktaSignInWidget';
import { withOktaAuth } from '@okta/okta-react';

import { USER_TYPES } from '../constants/ApplicationConstants';
import {
  DASHBOARD,
  SA_DASHBOARD,
  SR_DASHBOARD,
  ADMIN_BULLETIN,
} from '../constants/RouteConstants';
import { getUserTypeFromUrl } from '../utilities/UtilityFunctions';
import './login.less';
import { getEnvironmentValue, OKTA_DOMAIN } from '../../envConfig';
import CookieConsent from '../common/CookieConsent/CookieConsent';

class Login extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.setUrlParamsInStorage = this.setUrlParamsInStorage.bind(this);
    this.state = {
      authenticated: this.props.authState.isAuthenticated,
    };
  }
  async checkAuthentication() {
    const authenticated = this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  async onSuccess(res) {
    if (res.status === 'SUCCESS') {
      return this.props.oktaAuth.signInWithRedirect({
        sessionToken: res.session.token,
      });
    }
  }

  onError(err) {}

  setUrlParamsInStorage() {
    const urlParams = new URLSearchParams(window.location.search);
    const seedAdvisorID = urlParams.get('sa');
    seedAdvisorID &&
      window.localStorage.setItem('seed-advisor-id', seedAdvisorID);
    const zdPartnershipUuid = urlParams.get('zd');
    zdPartnershipUuid &&
      window.localStorage.setItem('zd-partnership-uuid', zdPartnershipUuid);
  }

  render() {
    if (this.state.authenticated === null) return null;
    const userType = getUserTypeFromUrl();
    let redirectUrl;
    switch (userType) {
      case USER_TYPES.GROWER:
        redirectUrl = DASHBOARD;
        this.setUrlParamsInStorage();
        break;
      case USER_TYPES.SEED_ADVISOR:
        redirectUrl = SA_DASHBOARD;
        break;
      case USER_TYPES.SALES_REPRESENTATIVE:
        redirectUrl = SR_DASHBOARD;
        break;
      case USER_TYPES.ADMIN:
        redirectUrl = ADMIN_BULLETIN;
        break;
      default:
        redirectUrl = DASHBOARD;
    }
    return this.state.authenticated ? (
      <Redirect to={{ pathname: redirectUrl }} />
    ) : (
      <div>
        <OktaSignInWidget
          baseUrl={`${getEnvironmentValue(OKTA_DOMAIN)}`}
          onSuccess={this.onSuccess}
          onError={this.onError}
          oktaAuth={this.props.oktaAuth}
        />
        <CookieConsent />
      </div>
    );
  }
}

export default withOktaAuth(Login);
