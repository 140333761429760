import React, { useEffect, useState } from 'react';
import { MessageHOC } from '../../shared';
import { Button, notification } from 'antd';
import {
  getCookie,
  setCookie,
  deleteCookie,
} from '../../utilities/UtilityFunctions';
import { CookieInfo } from './CookieInfo';
import messageIds from './messages';
import './cookieconsent.less';
export const CookieConsent = ({ getMessages }) => {
  const messages = getMessages(messageIds);
  const key = 'cookieconsent';
  const [showModal, setShowModal] = useState(false);

  const openCookieDetail = () => {
    setShowModal(true);
  };

  const closeCookieDetail = () => {
    setShowModal(false);
  };

  const closeNotification = () => {
    notification.close(key);
    deleteCookie('user_cookie_consent');
    setCookie('user_cookie_consent', 1, 30);
  };

  const btn = (
    <div className="button-section">
      <Button type="tertiary" className="mr10" onClick={openCookieDetail}>
        {messages.CookieConsent.tellmemore}
      </Button>
      <Button
        type="primary"
        className="ant-btn-success"
        onClick={closeNotification}
      >
        {messages.CookieConsent.okcontinue}
      </Button>
    </div>
  );

  useEffect(() => {
    const cookie_consent = getCookie('user_cookie_consent');
    if (cookie_consent === '') {
      notification.open({
        description: messages.CookieConsent.description,
        btn,
        key,
        placement: 'bottomLeft',
        duration: 0,
        bottom: 0,
        style: { width: '100vw', margin: '0px', textAlign: 'center' },
        closeIcon: <div></div>,
        className: 'coookie-notice-btn',
      });
    }
  }, []);
  return (
    <CookieInfo
      closeCookieDetail={closeCookieDetail}
      showModal={showModal}
      messages={messages}
    />
  );
};
export default MessageHOC()(CookieConsent);
