import { createSelector } from 'reselect';
import { getInitialState } from './reducer';
import {
  DEFAULT_LOCALE,
  COMMON_LOCALE,
} from '../constants/ApplicationConstants';

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = (state) => state.get('language', getInitialState());

/**
 * Select the language locale
 */

const makeSelectDefaultLocale = createSelector(
  selectLanguage,
  (languageState) => COMMON_LOCALE
);

const makeSelectLocale = createSelector(selectLanguage, (languageState) =>
  languageState.get('locale', DEFAULT_LOCALE)
);

export { selectLanguage, makeSelectLocale, makeSelectDefaultLocale };
