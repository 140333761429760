import React from 'react';
import { Form } from 'antd';

import { isRequired } from '../../utilities/UtilityFunctions';
import SelectDropdown from './SelectDropdown';
import './TextView.less';

const SelectRedux = ({
  input,
  label,
  opName,
  disabled,
  options,
  meta,
  rules,
  onChange,
  extraOptions,
  noDataText,
  isSearchable,
}) => {
  let customClass = meta.error ? 'ant-form-item-has-error' : '';
  if (isRequired(rules)) {
    customClass = `${customClass} item-required`;
  }
  return (
    <Form.Item label={label} labelCol={{ span: 24 }} className={customClass}>
      <SelectDropdown
        name={opName}
        value={String(input.value)}
        disabled={disabled}
        noDataText={noDataText}
        onChange={(name, key) => {
          onChange && onChange(key);
          input.onChange(key);
        }}
        options={options}
        extraOptions={extraOptions}
        isSearchable={isSearchable}
      />
      {meta.error && <div className="ant-form-item-explain">{meta.error}</div>}
    </Form.Item>
  );
};

export { SelectRedux };
