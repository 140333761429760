import { ApiHOC, MessageHOC } from '../../../shared';
import { connect } from 'react-redux';
import { DOWNLOAD_RESOURCE } from '../../../constants/ApiConstants';
import { LinkablePdf } from './LinkablePdf';

const mapStateToProps = (state, ownProps) => {
  return {
    loadBlobResource: (uuid, callback, errorCallback) => {
      ownProps.GET(
        DOWNLOAD_RESOURCE(uuid),
        (resp) => {
          resp.blob().then((resourceBlob) => {
            return callback(resourceBlob);
          });
        },
        errorCallback
      );
    },
  };
};

export default ApiHOC()(MessageHOC()(connect(mapStateToProps)(LinkablePdf)));
