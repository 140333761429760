import { getEnvironmentValue, SMARTLOOK_TRACKING } from '../../envConfig';

const isToggledOn = () => getEnvironmentValue(SMARTLOOK_TRACKING);

export function initializeTracking() {
  if (isToggledOn()) {
    const smartlook = window.smartlook;

    smartlook('init', '9e086ff179a0601fcff14ebd9c980e97f8403c01', {
      region: 'eu',
    });
  }
}

export function addUserIdentification(email) {
  if (isToggledOn()) {
    const smartlook = window.smartlook;

    smartlook('identify', email);
  }
}
