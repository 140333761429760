import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MessageHOC } from '../../../shared';
import { FinancialYearSelector } from './FinancialYearSelector';
import { setSalesYear } from './store/action';
import { getSelectedSalesYear } from './store/selectors';

const mapStateToProps = (state, { commonMessages }) => {
  return {
    messages: commonMessages,
    salesYear: getSelectedSalesYear(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const updateSalesYear = (salesYear) => {
    dispatch(setSalesYear(salesYear));
  };

  return {
    updateSalesYear: updateSalesYear,
  };
};

export default MessageHOC()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FinancialYearSelector)
  )
);
