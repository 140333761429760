import React from 'react';
import { Row, Col, Button } from 'antd';

export const ProfileIncomplete = ({
  messages,
  history,
  commonMessages,
  onClose,
  route,
}) => {
  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col span={24}>{messages.profile_not_complete_msg}</Col>
      </Row>
      <Row>
        <Col span={24} align="center">
          <Button onClick={onClose}>{commonMessages.buttons.cancel}</Button>
          &nbsp;&nbsp;
          <Button type="primary" onClick={() => history.push(route)}>
            {commonMessages.goto_profile}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
