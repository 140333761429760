import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import logo from '../../client/images/GH_GoldenAdvantage_Logo_RGB.png';
import { promiseResolver } from '../utilities/CommonApiUtils';
import { getUserTypeFromUrl } from '../utilities/UtilityFunctions';
import OktaError from './OktaError';
import {
  OKTA_IMPLICIT_CALLBACK,
  // APP_GROWER_LOGIN,
  APP_SEED_ADVISOR_LOGIN,
  APP_SALES_REPRESENTATIVE_LOGIN,
  GROWER_FORGOT_PASSWORD,
  SA_FORGOT_PASSWORD,
} from '../constants/RouteConstants';
import { USER_TYPES } from '../constants/ApplicationConstants';
import {
  getEnvironmentValue,
  OKTA_AUTH_SERVER,
  OKTA_CLIENT_ID,
  OKTA_DOMAIN,
} from '../../envConfig';

class OktaSignInWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPopup: false,
    };
    this.userType = getUserTypeFromUrl(USER_TYPES.SEED_ADVISOR);
    this.showForgotPasswordPage = this.showForgotPasswordPage.bind(this);
    this.openEmail = this.openEmail.bind(this);
  }
  async componentDidMount() {
    let oktaSigninObject = {
      logo: logo,
      baseUrl: this.props.baseUrl,
      authParams: {
        pkce: true,
        issuer: `${getEnvironmentValue(
          OKTA_DOMAIN
        )}/oauth2/${getEnvironmentValue(OKTA_AUTH_SERVER)}`,
        clientId: getEnvironmentValue(OKTA_CLIENT_ID),
        redirectUri: `${window.location.origin}${OKTA_IMPLICIT_CALLBACK}`,
      },
      i18n: {
        en: {
          // Labels
          'primaryauth.title': 'Sign in to Golden Advantage',
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Enter your email',
          'primaryauth.password.placeholder': 'Password',
          'primaryauth.password.tooltip': 'Enter your password',
          // Errors
          'error.username.required': 'Please enter an email',
          'error.password.required': 'Please enter a password',
          'errors.E0000004': 'Sign in failed!',
          'registration.signup.label': 'New User?',
        },
      },

      customButtons: [
        {
          title: 'Forgot password',
          className: 'btn-customAuth',
          click: this.showForgotPasswordPage,
        },
      ],
      features: {
        registration: false,
        rememberMe: false,
        showPasswordToggleOnSignInPage: true,
        idpDiscovery: true,
      },
      idpDiscovery: {
        requestContext:
          this.userType === USER_TYPES.GROWER
            ? `${window.location.origin}${APP_SEED_ADVISOR_LOGIN}`
            : this.userType === USER_TYPES.SALES_REPRESENTATIVE
            ? `${window.location.origin}${APP_SALES_REPRESENTATIVE_LOGIN}`
            : `${window.location.origin}${APP_SEED_ADVISOR_LOGIN}:`,
      },
    };

    oktaSigninObject.customButtons.push({
      title: 'Have Questions? ',
      className: 'button-as-text',
      click: () => {},
    });
    oktaSigninObject.customButtons.push({
      title:
        'Please contact Golden Advantage Support by phone at (855) 939-0058 or by email at ',
      className: 'contact-text',
      click: () => {},
    });
    oktaSigninObject.customButtons.push({
      title: 'support@goldenadvantage.com',
      className: 'email-link',
      click: this.openEmail,
    });

    const el = ReactDOM.findDOMNode(this);
    this.widget = new OktaSignIn(oktaSigninObject);
    let oktaWidget = this.widget;
    await oktaWidget.authClient.session.get().then(async (res) => {
      if (res.status === 'ACTIVE') {
        let [error, res] = await promiseResolver(
          oktaWidget.authClient.token.getWithoutPrompt({
            responseType: ['token', 'id_token'],
            scopes: ['openid', 'email', 'profile'],
          })
        );

        if (error) {
          this.setState({ showErrorPopup: true, error });
        } else {
          oktaWidget.authClient.tokenManager.add('idToken', res.tokens.idToken);
          oktaWidget.authClient.tokenManager.add(
            'accessToken',
            res.tokens.accessToken
          );
          this.props.oktaAuth.authStateManager.updateAuthState();
        }
      } else if (window.location.search.includes('fromLogin=true')) {
        await promiseResolver(
          oktaWidget.authClient.token.getWithRedirect({
            responseType: ['token', 'id_token'],
            scopes: ['openid', 'email', 'profile'],
          })
        );
      } else {
        this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
      }
    });
  }

  showForgotPasswordPage() {
    const pathname =
      this.userType === USER_TYPES.GROWER
        ? GROWER_FORGOT_PASSWORD
        : SA_FORGOT_PASSWORD;
    this.props.history.push(pathname);
  }

  openEmail() {
    window.open('mailto:support@goldenadvantage.com');
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    if (this.state.showErrorPopup) {
      return <OktaError error={this.state.error} />;
    }
    return (
      <div className="outer-container">
        <div className="okta-widget" />
      </div>
    );
  }
}

export default withRouter(OktaSignInWidget);
