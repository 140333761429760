import React, { useCallback, useEffect, useState } from 'react';
import { queryParams } from '../../../utilities/UtilityFunctions';
import { Result } from 'antd';

export const LinkablePdf = (props) => {
  const { loadBlobResource, getMessage } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasErrorLoadingPdf, setHasErrorLoadingPdf] = useState(false);
  const loadBlobResourceMemoizedCallback = useCallback(loadBlobResource, []);

  useEffect(() => {
    if (!isLoaded) {
      const pdfUuid = queryParams().get('id');
      const errorCallback = () => setHasErrorLoadingPdf(true);
      loadBlobResourceMemoizedCallback(
        pdfUuid,
        (blob) => {
          setIsLoaded(true);

          const url = window.URL.createObjectURL(blob);
          window.open(url, '_self');
        },
        errorCallback
      );
    }
  }, [loadBlobResourceMemoizedCallback, isLoaded]);

  if (hasErrorLoadingPdf) {
    const pdfNotFoundMessage = getMessage(
      'src.components.common.LinkablePdf.pdf_not_found'
    );
    return <Result status="warning" title={pdfNotFoundMessage} />;
  }
  return null;
};
